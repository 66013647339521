import { UnleashClient } from 'unleash-proxy-client'

export default {
  install (Vue, { configs, autoStart }) {
    if (!configs) {
      throw new Error('You must provide either a config or an unleash client.')
    }

    const unleash = new UnleashClient(configs)

    if (autoStart) {
      unleash.start()
    }

    Vue.prototype.$unleash = unleash
  }
}
