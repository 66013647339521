<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 81 81"
  >
    <g clip-path="url(#clip0_571_1986)">
      <path d="M7.83301 10.1666C3.69238 10.1666 0.333008 13.526 0.333008 17.6666C0.333008 20.026 1.44238 22.2448 3.33301 23.6666L37.333 49.1666C39.1143 50.4948 41.5518 50.4948 43.333 49.1666L77.333 23.6666C79.2236 22.2448 80.333 20.026 80.333 17.6666C80.333 13.526 76.9736 10.1666 72.833 10.1666H7.83301ZM0.333008 27.6666V60.1666C0.333008 65.6823 4.81738 70.1666 10.333 70.1666H70.333C75.8486 70.1666 80.333 65.6823 80.333 60.1666V27.6666L46.333 53.1666C42.7705 55.8385 37.8955 55.8385 34.333 53.1666L0.333008 27.6666Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_571_1986">
        <rect width="80" height="80" fill="currentColor" transform="translate(0.333008 0.166626)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'VIconEmail',
};
</script>
