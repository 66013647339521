import axios from 'axios'
import moment from 'moment'

import _assign from 'lodash/assign'

const VueTracking = function (options) {
  this._options = _assign({
    timeout: 1000 // 1 second
  }, options)

  this._requests = []
}

VueTracking.prototype.setHeaders = function (headers) {
  this._options.headers = _assign(this._options.headers || {}, headers)
}

VueTracking.prototype._sendRequests = function () {
  const { trackerURL } = this._options

  if (typeof trackerURL === undefined || !trackerURL || this._requests.length === 0) {
    return
  }

  const requests = this._requests

  this._requests = []

  axios.post(trackerURL, requests, {
    headers: this._options.headers || {}
  })
}

VueTracking.prototype.push = function (request) {
  if (typeof request !== 'object' || Array.isArray(request)) {
    return
  }

  request.ts = moment().format()

  this._requests.push(request)

  if (this._trackingTimer) {
    clearTimeout(this._trackingTimer)
    this._trackingTimer = null
  }

  this._trackingTimer = setTimeout(() => this._sendRequests(), this._options.timeout)
}

VueTracking.prototype.clear = function () {
  if (this._trackingTimer) {
    clearTimeout(this._trackingTimer)
    this._trackingTimer = null
  }

  this._sendRequests()
}

export default {
  install (Vue, options) {
    Vue.prototype.$tracking = new VueTracking(options)
  }
}
