import Home from './Home.vue'

import nativeBack from '@/mixins/nativeBack'

import {
  TRACKING_EVENT_SCREEN_VIEWED
} from '@/utils/constants'

export default function createHomeView(page) {
  return {
    name: page ? `${page}-home` : 'home',

    mixins: [nativeBack],

    beforeRouteEnter (to, from, next) {
      next((vm) => {
        vm.$parent.showNavbar = vm.$parent.activeNavbar = vm.$parent.showLogo = true

        vm.$tracking.push({
          event: TRACKING_EVENT_SCREEN_VIEWED,
          screen: page
        })
      })
    },

    render (h) {
      return h(Home, { props: { type: page }})
    }
  }
}
