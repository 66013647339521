import VHero from './VHero.vue'
import { createSimpleComponent } from '@/utils/helpers'

const VHeroTitle = createSimpleComponent('v-hero--title', 'h1')
const VHeroDescription = createSimpleComponent('v-hero--description')

export default VHero

export {
  VHero,
  VHeroTitle,
  VHeroDescription
}
