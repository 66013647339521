<template>
  <button
    class="v-btn"
    :class="objClasses"
    :type="type"
    :disabled="disabled"
    @keydown="handleKeyDown"
    @click="$emit('click', $event)"
  >
    <slot name="icon">
      <span
        v-if="icon"
        class="v-btn--icon"
      >
        <v-icon :icon="icon" />
      </span>
    </slot>

    <span
      v-if="$slots.default"
      class="v-btn--content"
    >
      <slot />
    </span>
  </button>
</template>

<script>
import VIcon from '@/components/VIcon'

import { keyCodes } from '@/utils/helpers'

export default {
  name: 'VButton',

  props: {
    type: {
      type: String,
      default: 'button'
    },
    color: String,
    hoverColor: String,
    icon: [String, Array],
    disabled: Boolean,
    block: Boolean,
    rounded: Boolean,
    size: {
      type: String,
      default: ''
    }
  },

  computed: {
    objClasses() {
      return {
        'v-btn--block': this.block,
        'v-btn--disabled': this.disabled,
        'v-btn--rounded': this.rounded,
        ...this.sizeClasses,
        ...this.themeClasses
      }
    },

    sizeClasses () {
      var size

      if (['md', 'lg'].indexOf(this.size) !== -1) {
        size = this.size
      }

      return size ? { [`v-btn--new--${size}`]: true } : false
    },

    themeClasses() {
      return {
        [`v-btn--${this.color}`]: !!this.color,
        [`v-btn--hover--${this.hoverColor}`]: !!this.hoverColor
      }
    }
  },

  components: {
    VIcon
  },

  methods: {
    handleKeyDown (e) {
      const keycode = e.keyCode || e.which

      if (keycode === keyCodes.enter || keycode === keyCodes.space) {
        e.stopPropagation()
      }

      this.$emit('button:keydown', e)
    }
  }
};
</script>

<style lang="scss">
@import './VButton.scss';
</style>
