<template>
  <div
    :class="{
      'v-image': true,
      'v-image--rounded': rounded
    }"
  >
    <div
      class="v-image--responsive"
      :style="{
        'padding-bottom': `${this.aspectRatio * 100}%`
      }"
    >
    </div>

    <slot>
      <v-fade-transition>
        <component
          v-if="currentImage"
          :is="imageComponent"
          :key="currentImage"
          class="v-image--image"
          v-bind="imageProps"
        />
      </v-fade-transition>
    </slot>
  </div>
</template>

<script>
import VFadeTransition from '@/transitions/VFadeTransition'

export default {
  name: 'v-image',

  props: {
    src: String,
    lazySrc: String,
    alt: String,
    aspectRatio: {
      type: Number,
      default: 1
    },
    rounded: Boolean,
    background: Boolean
  },

  data () {
    return {
      currentImage: null
    }
  },

  computed: {
    imageComponent () {
      return this.background ? 'div' : 'img'
    },

    imageProps () {
      var props = {}

      if (this.currentImage) {
        if (this.imageComponent === 'div') {
          props.styles = {
            backgroundImage: `url(${this.currentImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }
        } else {
          props.src = this.currentImage
          props.alt = this.alt
        }
      }

      return props
    }
  },

  watch: {
    src: {
      handler (src) {
        this.currentImage = null
        this.loadImage(src)
      },
      immediate: true
    },

    lazySrc: {
      handler (src) {
        if ([undefined, null, ''].indexOf(src) !== -1 || this.imageObserver) {
          return
        }

        this.currentImage = null

        this.imageObserver = new IntersectionObserver(this.handleIntersectObserver, {
          root: undefined,
          rootMargin: '0px',
          threshold: 0,
        })

        this.imageObserver.observe(this.$el)
      },
      immediate: true
    }
  },

  components: {
    VFadeTransition
  },

  methods: {
    handleIntersectObserver (entries, observe) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return

        if (this.lazySrc) {
          this.loadImage(this.lazySrc)
        }

        observe.unobserve(this.$el)
      })
    },

    onLoad () {
      this.currentImage = this.src || this.lazySrc
      this.$emit('image:loaded', this.currentImage)
    },

    onError () {
      this.$emit('image:error', this.src || this.lazySrc)
    },

    loadImage (src) {
      if ([undefined, null, ''].indexOf(src) !== -1) {
        return
      }

      const img = new Image()

      img.onload = () => this.onLoad()
      img.onerror = () => this.onError()

      img.src = src
    }
  }
}
</script>

<style lang="scss">
@import './VImage';
</style>
