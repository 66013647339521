<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path d="M21.3601 23.6467C18.9488 25.5183 15.9149 26.4007 12.876 26.1143C9.83702 25.8278 7.02146 24.3941 5.00239 22.1048C2.98332 19.8156 1.91252 16.843 2.00795 13.7921C2.10338 10.7411 3.35787 7.84125 5.51606 5.68268C7.67463 3.52449 10.5745 2.27 13.6254 2.17457C16.6763 2.07914 19.649 3.14995 21.9382 5.16902C24.2274 7.18808 25.6612 10.0037 25.9476 13.0426C26.2341 16.0815 25.3517 19.1154 23.4801 21.5267L29.5601 27.6067C29.7575 27.79 29.9018 28.0232 29.9779 28.2817C30.0539 28.5402 30.0588 28.8144 29.9921 29.0755C29.9253 29.3365 29.7894 29.5748 29.5986 29.765C29.4078 29.9553 29.1693 30.0906 28.9081 30.1567C28.6473 30.2235 28.3734 30.2189 28.115 30.1432C27.8567 30.0675 27.6235 29.9236 27.4401 29.7267L21.3601 23.6467ZM23.0001 14.1667C23.0178 12.9739 22.7983 11.7895 22.3541 10.6823C21.9099 9.57517 21.2501 8.56738 20.4129 7.7176C19.5756 6.86781 18.5778 6.19298 17.4774 5.73236C16.377 5.27174 15.196 5.03454 14.0031 5.03454C12.8101 5.03454 11.6291 5.27174 10.5287 5.73236C9.4283 6.19298 8.43047 6.86781 7.59326 7.7176C6.75604 8.56738 6.09617 9.57517 5.65201 10.6823C5.20785 11.7895 4.98827 12.9739 5.00606 14.1667C5.04129 16.5296 6.00468 18.7838 7.68812 20.4423C9.37155 22.1009 11.6399 23.0305 14.0031 23.0305C16.3662 23.0305 18.6346 22.1009 20.318 20.4423C22.0014 18.7838 22.9648 16.5296 23.0001 14.1667Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconSearch'
}
</script>
