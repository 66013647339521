<template>
  <div id="app" class="app">
    <div class="app--wrapper">
      <v-logo v-show="showLogo" />

      <v-navbar
        v-sn="snOptions.navbar"
        v-model="showNavbar"
        :disabled="!activeNavbar"
        centered
        @navbar:expand="handleNavbarExpand"
        @navbar:collapse="handleNavbarCollapse"
        ref="navbar"
      >
        <ul class="nav v-navbar--nav">
          <li
            v-if="isAuthenticated"
            class="nav-item nav-item--member"
          >
            <router-link
              v-sn-focusable
              class="nav-link"
              :to="{ name: 'profile-choose' }"
              @sn:willmove.native="handleNavbarMenuBeforeDeactive"
              @sn:deactive.native="handleNavbarMenuDeactive"
              @sn:active.native="handleNavbarMenuActive"
            >
              <span class="nav-link--icon">
                <v-image
                  v-if="user.avatarUrl"
                >
                  <img
                    class="v-image--image"
                    :src="user.avatarUrl"
                    :alt="user.name"
                  >
                </v-image>

                <v-icon v-else
                  icon="circle-user"
                />
              </span>

              <span class="nav-link--label">
                {{ user.name }}
              </span>
            </router-link>
          </li>

          <template v-for="(menu, key) in menus">
            <li
              v-if="!menu.auth || menu.auth === isAuthenticated"
              :key="key"
              :class="[
                'nav-item',
                `nav-item--${menu.path.name}`
              ]"
            >
              <a
                v-if="menu.name === 'menu.home'"
                v-sn-focusable="{ auto: false }"
                :class="{
                  'nav-link': true,
                  'is-active': isHome
                }"
                href=""
                @sn:willmove="handleNavbarMenuBeforeDeactive"
                @sn:deactive="handleNavbarMenuDeactive"
                @sn:active="handleNavbarMenuActive"
                @click.prevent="handleNavigateHome"
              >
                <span class="nav-link--icon">
                  <v-icon>
                    <v-icon-home />
                  </v-icon>
                </span>

                <span class="nav-link--label">
                  {{ $t(menu.name) }}
                </span>
              </a>

              <router-link
                v-else
                v-sn-focusable="{ auto: false }"
                class="nav-link"
                :to="menu.path"
                :replace="menu.replace && !isHome"
                @sn:willmove.native="handleNavbarMenuBeforeDeactive"
                @sn:deactive.native="handleNavbarMenuDeactive"
                @sn:active.native="handleNavbarMenuActive"
              >
                <span class="nav-link--icon">
                  <v-icon>
                    <v-icon-movie v-if="menu.icon === 'movie'" />
                    <v-icon-series v-else-if="menu.icon === 'series'" />
                    <v-icon-sport v-else-if="menu.icon === 'sport'" />
                    <v-icon-upcoming v-else-if="menu.icon === 'upcoming'" />
                    <v-icon-livetv v-else-if="menu.icon === 'livetv'" />
                    <v-icon-search v-else-if="menu.icon === 'search'" />
                    <v-icon-favorite v-else-if="menu.icon === 'favorite'" />
                    <v-icon-settings v-else-if="menu.icon === 'settings'" />
                    <v-icon-notification v-else-if="menu.icon === 'notification'" />
                  </v-icon>
                </span>

                <span class="nav-link--label">
                  {{ $t(menu.name) }}
                </span>
              </router-link>
            </li>
          </template>
        </ul>
      </v-navbar>

      <main
        v-if="!isPreloadStartUp"
        class="main"
      >
        <router-view class="main--wrapper" />
      </main>
    </div>
  </div>
</template>

<script>
import VLogo from '@/components/VLogo.vue'
import VNavbar from '@/components/VNavbar'

import {
  VIcon,
  VIconHome,
  VIconMovie,
  VIconSeries,
  VIconSport,
  VIconUpcoming,
  VIconLivetv,
  VIconSearch,
  VIconFavorite,
  VIconSettings,
  VIconNotification
} from '@/components/VIcon'

import VImage from '@/components/VImage'

import { UNLEASH_ENABLE_PLAYER_MUX_DATA } from '@/utils/constants'

import { keyCodes } from '@/utils/helpers'

import * as Sentry from '@sentry/vue'
import { mapState, mapGetters } from 'vuex'

import _pick from 'lodash/pick'

export default {
  name: 'App',

  data () {
    return {
      showLogo: false,
      showNavbar: false,
      showSplashBackground: false,
      activeNavbar: true,
      isPreloadStartUp: true,
      snOptions: {
        navbar: {
          section: 'navbar',
          options: {
            disabled: true,
            leaveFor: {
              up: '',
              left: '',
              down: ''
            }
          }
        },
      }
    }
  },

  computed: {
    ...mapState([
      'activePlatformBack',
      'device',
      'menus'
    ]),

    ...mapState('user', ['activeMessageUnread']),

    ...mapGetters({
      isWebOSTV: 'device/isWebOSTV',
      isWebOSTV4AndLatest: 'device/isWebOSTV4AndLatest',
      isAuthenticated: 'auth/isAuthenticated',
      user: 'user/activeUser'
    }),

    isActiveNavbar () {
      return this.showNavbar && this.activeNavbar
    },

    isHome () {
      return this.$route.name === 'home'
    },

    isUnsupportedPage () {
      return this.$route.name === 'unsupported'
    },

    isStartPage () {
      return window.history.length === 1
    }
  },

  watch: {
    isActiveNavbar (active) {
      const { section } = this.snOptions.navbar

      if (active) {
        this.$sn.enable(section)
      } else {
        this.$sn.disable(section)
      }
    },

    async isAuthenticated (active) {
      const auth = this.$store.state.auth
      const profile = active ? this.user.current_subprofile : null

      var headers = {
        Authorization: active ? `Bearer ${auth.token.access_token}` : null,
        'X-Monomax-Subprofile': profile ? profile.id : null
      }

      this.$pusher.setOptions({
        auth: { headers: _pick(headers, ['Authorization']) }
      })

      this.$tracking.clear()
      this.$tracking.setHeaders(headers)

      if (this.$unleash && !this.isPreloadStartUp) {
        await this.$unleash.stop()

        if (active) {
          this.$unleash.updateContext({ userId: this.user.id })
        } else {
          this.$unleash.removeContextField('userId')
        }

        await this.$unleash.start()
      }
    }
  },

  components: {
    VLogo,
    VNavbar,
    VIcon,
    VIconHome,
    VIconMovie,
    VIconSeries,
    VIconSport,
    VIconUpcoming,
    VIconLivetv,
    VIconSearch,
    VIconFavorite,
    VIconSettings,
    VIconNotification,
    VImage
  },

  created () {
    this.$sn.pause()
  },

  beforeMount () {
    this._bindEvents()
  },

  beforeDestroy () {
    this._unbindEvents()
  },

  methods: {
    _bindEvents () {
      window.addEventListener('DOMContentLoaded', this.handleDOMContentLoaded)
      window.addEventListener('keydown', this.handleBackButtonKeydown, true)
      window.addEventListener('cursorStateChange', this.handlePlatformCursor)
    },

    _unbindEvents () {
      window.removeEventListener('DOMContentLoaded', this.handleDOMContentLoaded)
      window.removeEventListener('keydown', this.handleBackButtonKeydown, true)
      window.removeEventListener('cursorStateChange', this.handlePlatformCursor)
    },

    handleDOMContentLoaded () {
      this._preloadStartUp()
    },

    handlePlatformCursor (e) {
      this.$store.commit('SET_ACTIVE_PLATFORM_CURSOR', e.detail ? e.detail.visibility : false)
    },

    handleNavbarExpand () {
      const { section } = this.snOptions.navbar
      this.$sn.focus(section)
    },

    handleNavbarCollapse () {
      this.$sn.move('right')
    },

    handleNavbarMenuBeforeDeactive (e) {
      const { direction } = e.detail
      const navbar = this.$refs.navbar

      if (direction === 'right' && navbar.isActive) {
        e.preventDefault()
        navbar.expand(false)
      }
    },

    handleNavbarMenuActive () {
      const navbar = this.$refs.navbar

      if (!navbar.isActive) {
        navbar.expand(true)
      }
    },

    handleNavbarMenuDeactive (e) {
      const { native, nextSectionId } = e.detail
      const { section } = this.snOptions.navbar
      const navbar = this.$refs.navbar

      if (!native && navbar.isActive && section !== nextSectionId) {
        navbar.expand(false)
      }
    },

    handleBackButtonKeydown (e) {
      const keycode = e.keyCode || e.which

      if (keyCodes.back.indexOf(keycode) !== -1) {
        const navbar = this.$refs.navbar

        if (navbar.isActive) {
          navbar.expand(false)
          return
        }

        if (this.activePlatformBack) {
          if (this.isUnsupportedPage) return

          if (this.isHome) {
            this.$router.push({ name: 'exit' })
          } else {
            if (this.isStartPage) {
              this.$router.replace({ name: 'home' })
            } else {
              this.$router.back()
            }
          }
        }

        this.$sn.resume()
      }
    },

    handleNavigateHome () {
      if (this.isHome) return

      if (this.isStartPage) {
        this.$router.replace({ name: 'home' })
      } else {
        this.$router.back()
      }
    },

    _loadDeviceInfo () {
      return this.$store.dispatch('device/FETCH_DEVICE_INFOMATION')
        .then((r) => {
          const isSucceeded = r && r.returnValue === true

          if (isSucceeded) {
            Sentry.setContext({
              name: 'Web0S',
              version: r.sdkVersion
            })
          }
        })
        .catch((e) => {
          Sentry.captureException(new Error(`Failed to retrieve tv device information (code: ${e.errorCode})`))
        })
    },

    _updateFeatuerFlags () {
      this.$store.commit('SET_ACTIVE_PLAYER_MUX_DATA', this.$unleash.isEnabled(UNLEASH_ENABLE_PLAYER_MUX_DATA))
    },

    async _preloadStartUp () {
      if (this.isWebOSTV) {
        await this._loadDeviceInfo()

        if (!this.isWebOSTV4AndLatest && !this.isUnsupportedPage) {
          await this.$router.replace({ name: 'unsupported' })
        }

        // const campaignPageName = 'campaign'

        // if (!this.isAuthenticated && this.$route.name !== campaignPageName) {
        //   await this.$router.replace({ name: campaignPageName })

        //   this.isPreloadStartUp = false
        //   return
        // }
      }

      await this.$store.dispatch('FETCH_NAVIGATION_BAR')

      if (this.isAuthenticated) {
        this.$unleash.updateContext({ userId: this.user.id })
      }

      this.$unleash.on('update', () => this._updateFeatuerFlags())

      await this.$unleash.start()

      this.$store.commit('SET_ACTIVE_PLATFORM_BACK', true)
      this.$nextTick(() => this.isPreloadStartUp = false)
    }
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
