<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M7.55507 3.99902H4.99951C4.44722 3.99902 3.99951 4.44674 3.99951 4.99902V7.55458M16.4439 3.99902H18.9995C19.5518 3.99902 19.9995 4.44674 19.9995 4.99902V7.55458M19.9995 16.4434V18.999C19.9995 19.5513 19.5518 19.999 18.9995 19.999H16.4439M7.55507 19.999H4.99951C4.44722 19.999 3.99951 19.5513 3.99951 18.999V16.4434M5.77729 12.8879H6.66618M8.44395 12.8879H9.33284M5.77729 10.999H10.1106C10.6629 10.999 11.1106 10.5513 11.1106 9.99902V5.7768M12.8884 5.7768V11.1101M16.4439 10.999H18.2217M14.6662 10.999H15.1106M13.7773 12.8879H15.1106M16.9995 12.8879H18.2217M18.2217 14.999H15.5551M15.5551 16.8879V18.2212M13.7773 14.999V18.2212M11.9995 18.2212V12.8879H11.1106M10.2217 14.6657V18.2212M18.2217 17.7768C18.2217 17.5313 18.0227 17.3323 17.7773 17.3323C17.5318 17.3323 17.3328 17.5313 17.3328 17.7768C17.3328 18.0222 17.5318 18.2212 17.7773 18.2212C18.0227 18.2212 18.2217 18.0222 18.2217 17.7768ZM18.2217 6.7768V8.33235C18.2217 8.88464 17.774 9.33235 17.2217 9.33235H15.6662C15.1139 9.33235 14.6662 8.88464 14.6662 8.33235V6.7768C14.6662 6.22451 15.1139 5.7768 15.6662 5.7768H17.2217C17.774 5.7768 18.2217 6.22451 18.2217 6.7768ZM6.77729 9.33235H8.33284C8.88513 9.33235 9.33284 8.88464 9.33284 8.33235V6.7768C9.33284 6.22451 8.88513 5.7768 8.33284 5.7768H6.77729C6.225 5.7768 5.77729 6.22451 5.77729 6.7768V8.33235C5.77729 8.88464 6.225 9.33235 6.77729 9.33235ZM7.44395 18.2212H6.77729C6.225 18.2212 5.77729 17.7735 5.77729 17.2212V15.6657C5.77729 15.1134 6.225 14.6657 6.77729 14.6657H7.44395C7.99624 14.6657 8.44395 15.1134 8.44395 15.6657V17.2212C8.44395 17.7735 7.99624 18.2212 7.44395 18.2212Z" stroke="white" stroke-width="1.23457" stroke-linecap="round" stroke-linejoin="round" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconScan'
}
</script>
