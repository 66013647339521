<template>
  <v-carousel
    v-if="lists.length"
    class="product-list"
    nested="outer"
    :perPage="listPerPage"
    gutter="32"
    :breakpoints="{
      1920: { gutter: 48 }
    }"
    direction="vertical"
    wheel
    lazyload
    @carousel:moved="handleListMoved"
    @carousel:ready="handleListReady"
    @mouseenter.native="handleListArrowToggle(true)"
    @mouseleave.native="handleListArrowToggle(false)"
    ref="productList"
  >
    <v-carousel-slide
      v-for="(list, listIndex) in lists"
      :key="list.slug"
    >
      <div class="v-header v-header--headline">
        <div class="v-header--title">
          {{ list.title }}
        </div>
      </div>

      <v-carousel
        v-sn="getSnOptions(listIndex)"
        :class="getDisplayClasses(list.type, list.display_hint)"
        class="product-list--inner"
        nested="inner"
        preloadBy="outer"
        :perPage="getDisplayItemPerPage(list.display_hint)"
        gutter="8"
        :breakpoints="{
          1920: { gutter: 12 }
        }"
        lazyload
        centered
        @carousel:moved="handleListItemMoved"
        ref="productListItem"
      >
        <v-carousel-slide
          v-for="(item, itemIndex) in list.items"
          :key="list.type === 'history' ? item.product.id : item.id"
        >
          <people-item
            v-if="list.type === 'people'"
            :index="itemIndex"
            :people="item"
            @sn:willmove.native="handleListItemBeforeDeactive"
            @sn:active.native="handleListItemActive(listIndex, itemIndex)"
            @mouseover.native="handleListItemAutoFocus"
          />

          <clip-item
            v-else-if="list.type === 'shortclips'"
            :index="itemIndex"
            :clip="item"
            @sn:willmove.native="handleListItemBeforeDeactive"
            @sn:active.native="handleListItemActive(listIndex, itemIndex)"
            @mouseover.native="handleListItemAutoFocus"
          />

          <product-item
            v-else
            :index="itemIndex"
            :product="item.product || item"
            :display="list.display_hint"
            :track="item.track"
            @sn:willmove.native="handleListItemBeforeDeactive"
            @sn:active.native="handleListItemActive(listIndex, itemIndex)"
            @mouseover.native="handleListItemAutoFocus"
          />
        </v-carousel-slide>

        <v-carousel-slide
          v-if="list.more && getPageRoutes(list)"
          class="d-flex"
        >
          <more-item
            :page="getPageRoutes(list)"
            @sn:willmove.native="handleListItemBeforeDeactive"
            @sn:active.native="handleListItemActive(listIndex, list.items.length)"
            @mouseover.native="handleListItemAutoFocus"
          />
        </v-carousel-slide>
      </v-carousel>
    </v-carousel-slide>

    <v-navigation
      v-model="showNavigation"
      ref="navigation"
      slot="arrow"
    />
  </v-carousel>
</template>

<script>
import {
  VCarousel,
  VCarouselSlide
} from '@/components/VCarousel'

import VNavigation from '@/components/VNavigation'

import ClipItem from './ClipItem.vue'
import PeopleItem from './PeopleItem.vue'
import ProductItem from './ProductItem.vue'
import MoreItem from './MoreItem.vue'

import { mapState } from 'vuex'

import _first from 'lodash/first'
import _forEach from 'lodash/forEach'

export default {
  name: 'ProductList',

  props: {
    lists: {
      type: Array,
      default: () => []
    },
    infinite: Boolean
  },

  data () {
    return {
      snOptions: {
        section: 'products-list',
        options: {
          straightOnly: true,
          leaveFor: {
            up: '@product-preview',
            left: '@navbar'
          }
        }
      },
      isListReady: false,
      isListMove: false,
      showNavigation: false,
      listPerPage: 1,
      itemPerPage: 8,
      itemBigPerPage: 7,
      itemPromoPerPage: 4,
      currentList: 0,
      currentListItem: 0,
    }
  },

  computed: {
    ...mapState(['activePlatformCursor']),

    $navbar () {
      return this.$root.$children[0].$refs.navbar
    }
  },

  watch: {
    activePlatformCursor (active) {
      if (!active) this.showNavigation = false
    },

    lists () {
      if (!this.isListReady) return

      this.$nextTick(() => {
        this.$refs.productList.reload()

        this.updateListArrowDisplay()
        this.updateListItemArrowDisplay()

        this.$parent.showLoading = false
        this.$sn.resume()
      })
    }
  },

  components: {
    VCarousel,
    VCarouselSlide,
    VNavigation,
    PeopleItem,
    ClipItem,
    ProductItem,
    MoreItem
  },

  methods: {
    _canListMove (direction) {
      if (['up', 'down'].indexOf(direction) === -1) {
        return false
      }

      const { slides, range } = this.$refs.productList
      const lastIndex = slides.length - 1
      const nextListIndex = direction === 'up' ? Math.max(this.currentList - 1, 0) : Math.min(this.currentList + 1, lastIndex)

      if (
        (slides.length <= this.listPerPage) ||
        (nextListIndex >= range.start && nextListIndex <= range.end)
      ) {
        return false
      } else {
        return true
      }
    },

    _canListItemMove (direction) {
      if (['left', 'right'].indexOf(direction) === -1) {
        return false
      }

      const { slides, range, index, perPage, offset } = this.$refs.productListItem[this.currentList]
      const lastIndex = slides.length - 1
      const nextIndex = direction === 'left' ? Math.max(this.currentListItem - 1, 0) : Math.min(this.currentListItem + 1, lastIndex)
      const edgeStart = offset - 1
      const edgeEnd = Math.min(lastIndex - edgeStart, lastIndex)

      if (
        (slides.length <= perPage) ||
        (index === nextIndex) ||
        (range.start === 0 && ((direction === 'left' && nextIndex <= edgeStart) || (direction === 'right' && nextIndex <= edgeStart))) ||
        (range.end === lastIndex && ((direction === 'left' && nextIndex >= edgeEnd) || (direction === 'right' && nextIndex >= edgeEnd)))
      ) {
        return false
      } else {
        return true
      }
    },

    handleListItemBeforeDeactive (e) {
      if (this.isListMove) {
        e.preventDefault()
        return
      }

      const { direction } = e.detail

      if (this._canListMove(direction)) {
        const productList = this.$refs.productList

        e.preventDefault()

        this.isListMove = true
        this.$sn.pause()

        if (direction === 'up') {
          productList.go(this.currentList - 1)
        } else if (direction === 'down') {
          productList.go(this.currentList + 1)
        }
      } else if (this._canListItemMove(direction)) {
        const productListItem = this.$refs.productListItem[this.currentList]

        this.isListMove = true

        if (direction === 'left') {
          productListItem.go(this.currentListItem - 1)
        } else if (direction === 'right') {
          productListItem.go(this.currentListItem + 1)
        }
      }
    },

    handleListItemActive (listIndex, itemIndex) {
      const list = this.lists[listIndex]
      const item = list.items[itemIndex]

      this.currentListItem = itemIndex

      if (item) {
        this.$emit('itemselected', item, list)
      }
    },

    handleListItemAutoFocus (e) {
      if (!this.isListMove) this.handleSnAutoFocus(e)
    },

    handleListMoved () {
      const nextList = this.$refs.productList
      const infiniteIndex = nextList.slides.length - nextList.perPage - 1
      const { section } = this.getSnOptions(nextList.index)

      this.currentList = nextList.index

      this.updateListSnActive()
      this.updateListArrowDisplay()
      this.updateListItemArrowDisplay()

      this.$sn.resume()
      this.$sn.focus(section)

      if (this.infinite && nextList.index === infiniteIndex) {
        this.$emit('infiniteload')
      }

      this.isListMove = false
    },

    handleListItemMoved () {
      this.updateListItemArrowDisplay()
      this.isListMove = false
    },

    handleListReady () {
      this.updateListSnActive()
      this.updateListArrowDisplay()
      this.updateListItemArrowDisplay()

      this.isListReady = true
      this.$parent.showLoading = false

      this.$nextTick(() => {
        if (this.$navbar.isActive) {
          this.$parent.preview = this.lists.length ? _first(_first(this.lists).items) : null
          this.$parent._loadPreviewBanner()
        } else {
          const { section } = this.getSnOptions(this.currentList)

          this.$sn.resume()
          this.$sn.focus(section)
        }
      })
    },

    handleListArrowToggle (show) {
      if (this.lists.length === 0) {
        return
      }

      const { section } = this.getSnOptions(this.currentList)

      this.$sn.set(section, {
        restrict: show ? 'self-only' : 'none'
      })

      this.showNavigation = show
    },

    updateListArrowDisplay () {
      const navigation = this.$refs.navigation
      const { index, finalIndex } = this.$refs.productList

      navigation.$refs.up[0].$el.disabled = index === 0
      navigation.$refs.down[0].$el.disabled = index === finalIndex
    },

    updateListItemArrowDisplay () {
      const navigation = this.$refs.navigation
      const { index, finalIndex } = this.$refs.productListItem[this.currentList]

      navigation.$refs.left[0].$el.disabled = index === 0
      navigation.$refs.right[0].$el.disabled = index === finalIndex
    },

    updateListItemArrowToggle () {
      const navigation = this.$refs.navigation
      const { slides, perPage } = this.$refs.productListItem[this.currentList]

      if (slides.length <= perPage) {
        navigation.toggle('hide', 'horizontal')
      } else {
        navigation.toggle('show', 'horizontal')
      }
    },

    updateListSnActive () {
      _forEach(this.lists, (list, index) => {
        const { section } = this.getSnOptions(index)

        if (index === this.currentList) {
          this.$sn.enable(section)
        } else {
          this.$sn.disable(section)
        }
      })
    },

    getSnOptions (index) {
      const { section, options } = this.snOptions

      return {
        section: index ? `${section}-${index}` : section,
        options
      }
    },

    getDisplayClasses (type, display) {
      var classes = {}

      if (type === 'people') {
        classes['product-list--items--people'] = true
      } else if (type === 'shortclip') {
        classes['product-list--items--shortclip'] = true
      } else {
        classes[`product-list--items--${display}`] = true
      }

      return {
        'product-list--items': true,
        ...classes
      }
    },

    getDisplayItemPerPage (display) {
      var itemPerPage = this.itemPerPage

      if (display === 'landscape' || display === 'tvshow' || display === 'shortclip') {
        itemPerPage = this.itemPromoPerPage
      } else if (display === 'bigportrait') {
        itemPerPage = this.itemBigPerPage
      }

      return itemPerPage
    },

    getDisplayImage (display, item) {
      var image = ''

      if (typeof item === 'undefined') {
        return image
      }

      if (display === 'landscape' || display === 'tvshow') {
        image = item.promo
      } else {
        image = item.poster
      }

      return image
    },

    getPageRoutes (list) {
      const { page, value } = list.more

      if (['category', 'tag', 'shortclips', 'people'].indexOf(page) !== -1) {
        return { name: page, params: { slug: value || list.slug } }
      } else {
        return false
      }
    }
  }
}
</script>
