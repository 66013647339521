import VIcon from './VIcon.vue'

import VIconHome from './icons/VIconHome.vue'
import VIconMovie from './icons/VIconMovie.vue'
import VIconSeries from './icons/VIconSeries.vue'
import VIconSport from './icons/VIconSport.vue'
import VIconUpcoming from './icons/VIconUpcoming.vue'
import VIconLivetv from './icons/VIconLivetv.vue'
import VIconSearch from './icons/VIconSearch.vue'
import VIconFavorite from './icons/VIconFavorite.vue'
import VIconNotification from './icons/VIconNotification.vue'
import VIconSettings from './icons/VIconSettings.vue'
import VIconBack from './icons/VIconBack.vue'
import VIconNext from './icons/VIconNext.vue'
import VIconUnlock from './icons/VIconUnlock.vue'
import VIconHamberger from './icons/VIconHamberger.vue'
import VIconScan from './icons/VIconScan.vue'
import VIconTv from './icons/VIconTv.vue'
import VIconSms from './icons/VIconSms.vue'
import VIconEmail from './icons/VIconEmail.vue'

export default VIcon

export {
  VIcon,
  VIconHome,
  VIconMovie,
  VIconSeries,
  VIconSport,
  VIconUpcoming,
  VIconLivetv,
  VIconSearch,
  VIconFavorite,
  VIconNotification,
  VIconSettings,
  VIconBack,
  VIconNext,
  VIconUnlock,
  VIconHamberger,
  VIconScan,
  VIconTv,
  VIconSms,
  VIconEmail
}
