<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
  >
    <path d="M29 26.1667C29 26.4319 28.8946 26.6863 28.7071 26.8738C28.5196 27.0613 28.2652 27.1667 28 27.1667H4C3.73478 27.1667 3.48043 27.0613 3.29289 26.8738C3.10536 26.6863 3 26.4319 3 26.1667C3 25.9015 3.10536 25.6471 3.29289 25.4596C3.48043 25.272 3.73478 25.1667 4 25.1667H28C28.2652 25.1667 28.5196 25.272 28.7071 25.4596C28.8946 25.6471 29 25.9015 29 26.1667ZM29 7.16669V21.1667C29 21.6971 28.7893 22.2058 28.4142 22.5809C28.0391 22.956 27.5304 23.1667 27 23.1667H5C4.46957 23.1667 3.96086 22.956 3.58579 22.5809C3.21071 22.2058 3 21.6971 3 21.1667V7.16669C3 6.63625 3.21071 6.12755 3.58579 5.75247C3.96086 5.3774 4.46957 5.16669 5 5.16669H27C27.5304 5.16669 28.0391 5.3774 28.4142 5.75247C28.7893 6.12755 29 6.63625 29 7.16669ZM20.5 14.1667C20.4999 13.9984 20.4574 13.8328 20.3764 13.6853C20.2953 13.5378 20.1783 13.4132 20.0362 13.3229L14.5363 9.82294C14.3851 9.72689 14.2109 9.67321 14.0319 9.6675C13.8528 9.6618 13.6756 9.70427 13.5186 9.79049C13.3616 9.87672 13.2307 10.0035 13.1394 10.1576C13.0482 10.3118 13.0001 10.4876 13 10.6667V17.6667C13.0001 17.8458 13.0482 18.0216 13.1394 18.1757C13.2307 18.3299 13.3616 18.4567 13.5186 18.5429C13.6756 18.6291 13.8528 18.6716 14.0319 18.6659C14.2109 18.6602 14.3851 18.6065 14.5363 18.5104L20.0362 15.0104C20.1783 14.9202 20.2953 14.7955 20.3764 14.648C20.4574 14.5006 20.4999 14.335 20.5 14.1667Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconUpcoming'
}
</script>
