<template>
  <div
    v-sn-focusable
    class="v-carousel--slide--link peopleitem"
    data-sn-autofocus="disable"
    @click="handleClick"
    @keypress.enter="handleClick"
    role="link"
  >
    <v-avatar
      :image="people.image_url"
      :caption="people.title"
      size="auto"
      rounded
    />
  </div>
</template>

<script>
import VAvatar from '@/components/VAvatar'

import _find from 'lodash/find'

export default {
  name: 'PeopleItem',

  props: {
    index: Number,
    people: Object,
    track: Array
  },

  components: {
    VAvatar
  },

  methods: {
    handleClick () {
      const track = _find(this.track, { on: 'click' })

      if (track && track.params) {
        this.$tracking.push({
          event: track.event,
          ...track.params
        })
      }

      this.$router.push({
        name: 'people',
        params: { slug: this.people.slug }
      })
    }
  }
}
</script>
