<template>
  <div class="home-list offset-navbar">
    <product-preview
      :item="preview ? preview.product || preview : null"
      :banner="banner"
      :videos="videos"
    >
      <v-button
        v-if="!showLoading && navs.length"
        v-sn-focusable
        class="v-btn--genre mt-3"
        color="secondary"
        hoverColor="primary"
        icon="chevron-down"
        @click="handleGenreDialogToggle"
      >
        {{ $t('buttons.genre_list') }}
      </v-button>
    </product-preview>

    <div class="home-list--content">
      <product-list
        :lists="computeList"
        :infinite="activeInfinite"
        @infiniteload="handleInfiniteLoad"
        @itemselected="handleItemSelected"
        ref="productList"
      />
    </div>

    <genre-list-dialog
      v-if="navs.length"
      v-model="showGenreList"
      :items="navs"
    />

    <v-spinner-loader v-model="showLoading" />
  </div>
</template>

<script>
import ProductPreview from '../components/ProductPreview.vue'
import ProductList from '../components/ProductList.vue'
import GenreListDialog from '../components/GenreListDialog.vue'

import VSpinnerLoader from '@/components/VSpinnerLoader'
import VButton from '@/components/VButton'

import _slice from 'lodash/slice'

export default {
  name: 'HomeList',

  data () {
    return {
      activeInfinite: true,
      showLoading: true,
      showBanner: true,
      showVideo: false,
      showGenreList: false,
      banner: null,
      videos: [],
      navs: [],
      listPerPage: 5,
      listPage: 1,
      lists: [],
      preview: null
    }
  },

  computed: {
    computeList () {
      return _slice(this.lists, 0, this.listPage * this.listPerPage)
    }
  },

  components: {
    ProductPreview,
    ProductList,
    GenreListDialog,
    VSpinnerLoader,
    VButton
  },

  beforeMount () {
    this._loadList()
  },

  beforeDestroy () {
    this._clearPreviewBanner()
    this._clearPreviewTrailer()
  },

  methods: {
    _clearPreviewBanner () {
      this.banner = null

      this._previewBannerTimer && clearTimeout(this._previewBannerTimer)
      this._previewBannerTimer = this.banner = null
    },

    _loadPreviewBanner () {
      this._clearPreviewBanner()

      this._previewBannerTimer = setTimeout(() => {
        this.banner = this.preview ? this.preview.banner : null
      }, 1000) // 1 seconds.
    },

    _clearPreviewTrailer () {
      this.videos = []

      this._previewTrailerTimer && clearTimeout(this._previewTrailerTimer)
      this._previewTrailerTimer = null
    },

    _loadPreviewTrailer () {
      this._clearPreviewTrailer()

      this._previewTrailerTimer = setTimeout(() => {
        this.$store.dispatch('FETCH_PRODUCT_TRAILER', { id: this.preview.id })
          .then((trailers) => this.videos = trailers)
      }, 3000) // 3 seconds.
    },

    handleInfiniteLoad () {
      this.$sn.pause()
      this.showLoading = true

      setTimeout(() => {
        this.listPage++

        if (this.computeList.length === this.lists.length) {
          this.activeInfinite = false
        }
      }, 200)
    },

    handleItemSelected (item, list) {
      const preview = item.product || item
      const hasProductItem = ['history', 'product', 'shortclips'].indexOf(list.type) !== -1

      if ((this.preview && this.preview.id === preview.id) || !hasProductItem) {
        return
      }

      this.preview = preview
      this._loadPreviewBanner()

      if (['history', 'product'].indexOf(list.type) !== -1) {
        this._loadPreviewTrailer()
      } else {
        this._clearPreviewTrailer()
      }
    },

    handleGenreDialogToggle () {
      this.showGenreList = true
    }
  }
}
</script>

<style lang="scss">
@import './HomeList.scss';
</style>
