<template>
  <v-hero
    v-sn="snOptions"
    class="home-list--header"
    :banner="banner"
    :videos="videos"
  >
    <template v-if="item">
      <v-image
        v-if="item.logo"
        class="home-list--header--logo"
        :src="item.logo"
        :alt="item.title"
        :aspect-ratio=".75"
      />

      <v-hero-title
        v-else
      >
        {{ item.title }}
      </v-hero-title>

      <div class="mb-2">
        <v-chip
          v-if="item.is_top10"
          class="v-chip--top10 text-bold mr-2"
          text="TOP10"
        />

        <v-chip
          class="v-chip--kind text-bold mr-2"
          :text="convertKindToText(item.kind)"
        />

        <v-chip
          class="v-chip--rate text-bold rounded-2 mr-2"
          :text="$t('product.metas.rate', { rate: convertRateToText(item.rate) })"
        />
      </div>

      <v-hero-description>
        {{ item.description }}
      </v-hero-description>
    </template>

    <slot slot="custom" />
  </v-hero>
</template>

<script>
import {
  VHero,
  VHeroTitle,
  VHeroDescription
} from '@/components/VHero'

import VImage from '@/components/VImage'
import VChip from '@/components/VChip'

import convertable from '@/mixins/convertable.js'

export default {
  name: 'ProductPreview',

  mixins: [convertable],

  props: {
    item: Object,
    banner: String,
    videos: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      snOptions: {
        section: 'product-preview',
        options: { straightOnly: true }
      }
    }
  },

  components: {
    VHero,
    VHeroTitle,
    VHeroDescription,
    VImage,
    VChip
  }
}
</script>
