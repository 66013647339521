<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
  >
    <path d="M24.2833 9.97831L24.1698 9.41091C25.3045 9.12724 26.1556 9.18395 26.1556 9.18395V9.75134C26.0988 9.8081 25.1911 9.8081 24.2833 9.97831Z" fill="currentColor"/>
    <path d="M17.871 8.33051C17.7579 8.33051 17.6448 8.27396 17.5317 8.27396V7.70862C17.6448 7.70862 17.8144 7.70862 17.9275 7.76517L17.871 8.33051Z" fill="currentColor"/>
    <path d="M18.8364 16.4463L18.7229 15.8789C20.0846 15.652 21.3328 15.4818 22.2974 15.4818L22.3541 16.0491C22.3541 16.0491 20.4817 16.2194 18.8364 16.4463Z" fill="currentColor"/>
    <path d="M19.0637 3.28344C19.1205 3.28344 19.2339 3.22668 19.2907 3.22668H19.3474C19.2906 3.28344 19.1772 3.28344 19.0637 3.28344Z" fill="currentColor"/>
    <path d="M16.1688 16.7849C16.1688 16.7849 16.0555 16.5017 15.829 16.0485L16.3387 15.7654C16.5086 16.1052 16.6219 16.3318 16.7352 16.5017L16.1688 16.7849Z" fill="currentColor"/>
    <path d="M24.3393 18.8856L24.0556 18.5452C23.8854 18.2615 23.6584 17.9211 23.4315 17.5807L23.8854 17.2402C24.1124 17.5239 24.3393 17.8643 24.5663 18.1481L24.6797 18.3183L24.3393 18.8856Z" fill="currentColor"/>
    <path d="M8.84946 12.6995L8.62329 12.1907H8.67985C8.7364 12.1907 8.7929 12.1342 8.84946 12.1342L9.01907 12.643L8.84946 12.6995Z" fill="currentColor"/>
    <path d="M8.3951 13.1543L7.94202 12.8145C8.16858 12.5313 8.3385 12.3614 8.50841 12.2481L8.84823 12.7012C8.90483 12.6446 8.67832 12.8145 8.3951 13.1543Z" fill="currentColor"/>
    <path d="M2.78004 17.6945C2.66658 16.7867 2.66658 15.8222 2.78004 14.9144C2.8935 15.5952 3.06371 16.2193 3.34743 16.73L2.78004 17.6945Z" fill="currentColor"/>
    <path d="M29.3329 16.1632C29.3329 16.6738 29.3329 17.1845 29.2194 17.6951C29.1626 18.0355 29.1059 18.376 29.0492 18.7732C29.0492 18.7164 28.9924 18.6597 28.9924 18.6597L29.3329 16.1632Z" fill="currentColor"/>
    <path d="M25.0767 19.5672C25.0767 19.5672 26.5519 19.5672 27.8569 19.2268V20.702L28.1973 21.6665C27.2895 23.5956 25.9845 25.2977 24.3392 26.6027L22.864 25.241C22.864 25.241 24.9633 21.8935 25.0767 19.5672Z" fill="#242424"/>
    <path d="M24.3396 18.3186C24.6794 18.7717 24.906 19.2248 25.0759 19.5646C25.0759 19.5646 24.736 18.9983 24.3396 18.3186Z" fill="#242424"/>
    <path d="M27.8572 19.1697C28.3111 19.0563 28.7082 18.8861 29.0487 18.6024C29.0487 18.6024 29.0487 18.6591 29.1054 18.7158C28.8785 19.7371 28.5948 20.7016 28.1976 21.6094L27.8572 20.6449V19.1697Z" fill="#242424"/>
    <path d="M17.4183 26.1486C17.4183 26.1486 20.4254 26.1486 22.468 25.411L24.2269 26.6593C21.6737 28.6451 18.4964 29.7231 15.0921 29.4394L14.468 28.872C15.6028 28.5884 17.4183 26.1486 17.4183 26.1486Z" fill="currentColor"/>
    <path d="M22.8652 25.2404L24.3404 26.6021C24.2836 26.6021 24.2836 26.6589 24.2269 26.6589L22.468 25.4106C22.5815 25.3539 22.695 25.2972 22.8652 25.2404Z" fill="currentColor"/>
    <path d="M12.9927 28.7017V29.1556C10.7799 28.645 8.79411 27.6237 7.14868 26.1485L8.51038 25.8649C8.96432 26.7726 11.3473 28.1911 12.9927 28.7017Z" fill="currentColor"/>
    <path d="M13.0494 29.1551V28.7012C13.6735 28.8714 14.1842 28.9848 14.4679 28.9281L15.092 29.4955C14.865 29.4955 14.6948 29.4388 14.4679 29.4388C13.9573 29.3253 13.5033 29.2685 13.0494 29.1551Z" fill="currentColor"/>
    <path d="M10.8365 4.9285C9.13433 5.6661 7.26199 8.72993 7.26199 8.72993C7.26199 8.72993 7.26199 8.72993 7.26199 8.67318V8.61642C7.26199 8.61642 7.26199 8.61642 7.26199 8.55967C7.26199 8.55967 7.26199 8.55967 7.26199 8.61642V8.67318C7.26199 8.67318 7.26199 8.67318 7.26199 8.72993C7.26199 8.72993 5.44641 9.581 3.97119 10.3753C5.38965 7.36823 7.94284 5.04201 10.9499 3.79376C10.8932 4.2477 10.8365 4.64483 10.8365 4.9285Z" fill="currentColor"/>
    <path d="M6.24074 17.4675C6.24074 17.4675 6.69462 19.964 8.51026 21.8363C7.99962 23.5952 8.51026 25.9782 8.51026 25.9782L7.14856 26.2618C4.70882 24.1058 3.12015 21.0987 2.72302 17.808L3.23366 16.8434C3.29036 16.6732 4.99249 17.4108 6.24074 17.4675Z" fill="currentColor"/>
    <path d="M24.3405 18.3756C24.1703 18.092 23.9433 17.7515 23.7164 17.4111C23.0355 16.5033 22.4114 15.7657 22.4114 15.7657C22.4114 15.7657 20.4823 15.936 18.837 16.1629C17.4753 16.3899 16.454 16.6736 16.454 16.6736L14.2412 21.8367L16.0001 24.1629L17.4186 26.092C17.4186 26.092 20.4256 26.092 22.4682 25.3544C22.5816 25.2977 22.7519 25.241 22.8654 25.1842C22.8654 25.1842 25.0214 21.8367 25.0781 19.5104C25.078 19.5671 24.7376 18.9997 24.3405 18.3756Z" fill="currentColor"/>
    <path d="M3.74429 10.9423L7.37552 9.46716C7.71594 10.6587 8.68047 12.4175 8.68047 12.4175C8.51025 12.531 8.34004 12.7012 8.16983 12.9282C7.48898 13.6657 6.41094 15.2544 6.24073 17.4104L3.23365 16.6728C3.00669 16.1622 2.83647 15.5381 2.66626 14.8572C2.66626 14.7438 2.66626 14.687 2.66626 14.5736C2.89323 13.3253 3.23365 12.0771 3.74429 10.9423Z" fill="#242424"/>
    <path d="M7.37561 9.46734L3.74438 10.9425C3.80114 10.7723 3.9146 10.6021 3.97135 10.3751C5.44657 9.5808 7.26215 8.72974 7.26215 8.72974C7.26215 8.89995 7.31891 9.18362 7.37561 9.46734Z" fill="#242424"/>
    <path d="M3.29065 16.6729L6.29773 17.4105C4.99278 17.4105 3.29065 16.6729 3.29065 16.6729Z" fill="#242424"/>
    <path d="M14.2406 21.8362L15.9995 24.1624L14.2974 25.2404L13.3329 26.4887L8.56689 25.9213V21.7794C8.56689 21.7794 12.5953 22.6305 14.2406 21.8362Z" fill="#242424"/>
    <path d="M13.3331 26.4889L14.2977 25.2406L15.9998 24.2194L17.4182 26.1485C17.4182 26.1485 15.5459 28.5882 14.4112 28.9286L14.4679 26.6024L13.3331 26.4889Z" fill="#242424"/>
    <path d="M12.9924 26.8865L13.0492 28.7021C11.4038 28.1347 9.02083 26.773 8.56689 25.9219L13.3329 26.4893L12.9924 26.8865Z" fill="#242424"/>
    <path d="M13.333 26.4891L14.5245 26.6025L14.4678 28.9288C14.1841 29.0422 13.6735 28.9288 13.0493 28.7018L12.9926 26.8862L13.333 26.4891Z" fill="#242424"/>
    <path d="M8.5676 21.7794V25.9213C8.5109 25.9212 8.05696 23.5382 8.5676 21.7794Z" fill="#242424"/>
    <path d="M22.3542 15.7656C21.2195 15.8223 19.9145 15.9926 18.8365 16.2195C17.7585 16.3897 16.794 16.5599 16.4535 16.7301C16.3968 16.5599 16.2833 16.2762 16.1131 15.9925C15.6025 15.028 14.638 13.2123 13.5032 11.8506C14.5812 10.2053 17.5883 8.10596 17.5883 8.10596C17.7017 8.10596 17.8153 8.10596 17.9287 8.16271C19.7443 8.44638 22.7514 10.4322 22.7514 10.4322C22.7514 10.4322 22.0706 12.5315 22.3542 15.7656Z" fill="#242424"/>
    <path d="M16.4545 16.6738C16.7949 16.5036 17.7594 16.3334 18.8374 16.1632C17.4757 16.4469 16.4545 16.6738 16.4545 16.6738Z" fill="#242424"/>
    <path d="M16.4538 16.6732L14.241 21.8363C11.8581 22.4037 8.56725 21.7796 8.56725 21.7796L6.29773 17.4108H6.35448C6.35448 17.4108 6.41124 17.4108 6.46794 17.4108C6.5814 17.4108 6.69491 17.4108 6.75161 17.4108C6.69486 17.4108 6.5814 17.4108 6.46794 17.4108C6.41119 17.4108 6.41119 17.4108 6.35448 17.4108C6.29778 17.4108 6.29773 17.4108 6.29773 17.4108C6.29773 17.4108 7.2623 14.2902 8.22688 12.9285C8.51055 12.5881 8.73752 12.4179 8.73752 12.4179C8.79427 12.4179 8.85098 12.3611 8.90773 12.3611C10.3262 11.9072 13.4467 11.7937 13.4467 11.7937C13.4467 11.7937 15.2623 14.4604 16.0567 15.9356C16.3403 16.3894 16.4538 16.6732 16.4538 16.6732Z" fill="currentColor"/>
    <path d="M6.24158 17.4668L8.5111 21.8356C6.69546 19.9632 6.24158 17.4668 6.24158 17.4668Z" fill="currentColor"/>
    <path d="M8.22768 12.9284C7.2064 14.2901 6.29858 17.4107 6.29858 17.4107C6.46885 15.2547 7.54683 13.7228 8.22768 12.9284Z" fill="currentColor"/>
    <path d="M14.2409 21.8362C12.6522 22.5738 8.56714 21.7794 8.56714 21.7794C8.56714 21.7794 11.8579 22.4603 14.2409 21.8362Z" fill="currentColor"/>
    <path d="M16.1134 15.9356C15.2623 14.4036 13.5034 11.7937 13.5034 11.7937C14.5815 13.2122 15.6027 14.971 16.1134 15.9356Z" fill="currentColor"/>
    <path d="M15.9995 4.81542C14.4108 4.58845 12.425 4.70196 11.4605 4.81542C11.0633 4.87217 10.8363 4.87217 10.8363 4.87217C9.13418 5.60978 7.26184 8.67361 7.26184 8.67361C7.26184 8.78707 7.3753 9.01404 7.48881 9.35447C7.88599 10.4325 8.73705 12.3615 8.73705 12.3615C8.79381 12.3615 8.85051 12.3048 8.90727 12.3048C10.4959 11.7374 13.4463 11.7374 13.4463 11.7374C14.7512 9.58138 17.5314 7.99271 17.5314 7.99271C17.5314 7.99271 16.3399 5.32606 15.9995 4.81542Z" fill="currentColor"/>
    <path d="M8.73805 12.418C8.73805 12.418 7.77353 10.6024 7.43311 9.46758L7.48986 9.41083C7.88699 10.4889 8.73805 12.418 8.73805 12.418Z" fill="currentColor"/>
    <path d="M7.48897 9.41024L7.37571 9.4669C7.31905 9.18373 7.26245 8.95716 7.26245 8.73059C7.2624 8.8439 7.37566 9.12707 7.48897 9.41024Z" fill="currentColor"/>
    <path d="M13.4469 11.7939C14.7518 9.63793 17.532 8.04926 17.532 8.04926C17.532 8.04926 14.5249 10.1486 13.4469 11.7939Z" fill="currentColor"/>
    <path d="M13.4471 11.794C13.4471 11.794 10.2698 11.9075 8.90808 12.3614C10.4399 11.794 13.4471 11.794 13.4471 11.794Z" fill="currentColor"/>
    <path d="M22.6951 10.318C22.6951 10.318 20.1419 8.33215 17.8723 8.04843C17.7589 8.04843 17.6454 7.99168 17.5319 7.99168C17.2482 6.74343 16 4.81439 16 4.81439C16 4.81439 17.9291 3.56614 19.0638 3.28247C19.1773 3.28247 19.2908 3.28247 19.3475 3.28247C19.6312 3.33923 19.9716 3.45268 20.2553 3.56614C20.8227 3.79311 21.6738 4.19024 22.4681 4.53066C23.5461 5.15476 24.5106 5.89237 25.3617 6.74343C25.7021 7.36753 26.0426 8.3321 26.0426 9.52359C26.0426 9.52359 25.1915 9.46684 24.1702 9.75056C23.6029 9.86407 22.9788 10.0342 22.6951 10.318Z" fill="currentColor"/>
    <path d="M22.6953 10.3188C22.6953 10.3188 19.6882 8.33298 17.8726 8.04926C20.1421 8.33293 22.6953 10.3188 22.6953 10.3188Z" fill="currentColor"/>
    <path d="M16 4.81488C16 4.81488 17.1915 6.74398 17.5319 7.99217C17.5319 8.04892 16.3404 5.32552 16 4.81488Z" fill="currentColor"/>
    <path d="M15.9998 4.81577C15.9998 4.81577 13.3899 4.30513 11.5743 4.64555L11.631 4.5321L11.4608 3.62428C13.3332 2.94343 15.4325 2.65976 17.5317 2.94343C18.1558 3.00018 18.7232 3.11364 19.2906 3.28385C19.2339 3.28385 19.1204 3.28385 19.0636 3.34061C17.5317 3.51082 15.9998 4.81577 15.9998 4.81577Z" fill="#242424"/>
    <path d="M11.574 4.70163C11.2904 4.75838 11.0634 4.81509 10.8364 4.9286C10.8364 4.70163 10.9499 4.24775 11.0066 3.85056C11.1769 3.73711 11.2903 3.68035 11.4605 3.6236L11.6307 4.58812L11.574 4.70163Z" fill="#242424"/>
    <path d="M11.4608 4.81561L11.5743 4.64539C13.3899 4.36172 15.9998 4.81561 15.9998 4.81561C14.4112 4.58869 12.4254 4.7589 11.4608 4.81561Z" fill="#242424"/>
    <path d="M10.8364 4.92829C11.063 4.81503 11.2895 4.75838 11.5727 4.70172L11.4595 4.81498C11.063 4.87169 10.8364 4.92829 10.8364 4.92829Z" fill="#242424"/>
    <path d="M19.064 3.28278C17.9859 3.56645 16.0001 4.81469 16.0001 4.81469C16.0001 4.81469 17.532 3.50974 19.064 3.28278Z" fill="#242424"/>
    <path d="M28.8224 12.588C28.709 12.2476 28.6522 11.9072 28.482 11.6235C27.3473 10.1483 26.0423 9.52417 26.0423 9.52417C26.0423 9.52417 25.0778 9.58092 24.1699 9.75114C23.7161 9.8646 23.1487 10.0916 22.638 10.3752C22.2408 12.1341 22.2976 15.822 22.2976 15.822C22.5813 15.9923 23.0919 16.7299 23.6026 17.4674C23.8295 17.7511 24.0564 18.0915 24.2834 18.3752C24.6238 18.8291 24.8508 19.2831 25.021 19.6235C25.021 19.6235 25.021 19.51 25.021 19.3965C25.021 19.4533 25.021 19.5667 25.021 19.6235C25.021 19.6235 26.4962 19.6235 27.8012 19.2831C28.2551 19.1696 28.6522 18.9994 28.9927 18.7157L29.2763 16.2192C29.3331 14.9142 29.1629 13.7227 28.8224 12.588Z" fill="currentColor"/>
    <path d="M22.3549 15.7653C22.6385 15.9355 23.1492 16.6731 23.6598 17.4106C23.0357 16.5029 22.3549 15.7653 22.3549 15.7653Z" fill="currentColor"/>
    <path d="M22.6949 10.3193C22.2977 12.0782 22.3544 15.7661 22.3544 15.7661C22.0708 12.532 22.6949 10.3193 22.6949 10.3193Z" fill="currentColor"/>
    <path d="M24.2261 9.69495C23.7722 9.8084 23.2049 10.0354 22.6942 10.319C22.9779 10.0353 23.602 9.86516 24.2261 9.69495Z" fill="currentColor"/>
    <path d="M26.0985 9.52482C26.0985 9.52482 26.2119 8.21987 25.7013 7.02838C26.8928 8.27663 27.8573 9.80854 28.4815 11.4539C27.8574 10.6028 27.063 9.80849 26.0985 9.52482Z" fill="#242424"/>
    <path d="M25.4181 6.74353C25.5315 6.85699 25.5883 6.91374 25.7018 7.0272C26.2124 8.21869 26.0989 9.52364 26.0989 9.52364C26.0989 8.27545 25.7585 7.36763 25.4181 6.74353Z" fill="#242424"/>
    <path d="M26.0988 9.52423C27.0633 9.8079 27.8576 10.659 28.425 11.4533C28.425 11.5101 28.4817 11.5668 28.4817 11.6235C27.4038 10.0916 26.0988 9.52423 26.0988 9.52423Z" fill="#242424"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconSport'
}
</script>
