<template>
  <div class="v-hero">
    <div class="v-hero--background">
      <div class="v-hero--background--wrapper">
        <v-fade-transition
          @fadeout="handleBannerToggle(false)"
        >
          <div
            v-show="showBanner"
            class="v-hero--background--image"
          >
            <v-image
              :src="currentBanner"
              :aspect-ratio=".5625"
            />
          </div>
        </v-fade-transition>

        <v-fade-transition
          @fadeout="handleVideoToggle(false)"
        >
          <div
            v-show="showVideo"
            class="v-hero--background--video"
          >
            <v-player
              :shakaOptions="{
                streaming: { preferNativeHls: true }
              }"
              :playlists="currentVideos"
              :ui="false"
              autoplay
              @player:playing="handleVideoPlaying"
              @player:ended="handleVideoEnded"
              ref="player"
            />
          </div>
        </v-fade-transition>
      </div>
    </div>

    <div class="v-hero--container">
      <h1
        v-if="title"
        class="v-hero--title"
      >
        {{ title }}
      </h1>

      <div
        v-if="description"
        class="v-hero--content"
      >
        {{ description }}
      </div>

      <slot />
    </div>

    <slot name="custom" />
  </div>
</template>

<script>
import VImage from '@/components/VImage'
import VPlayer from '@/components/VPlayer'
import VFadeTransition from '@/transitions/VFadeTransition'

export default {
  name: 'VHero',

  props: {
    title: String,
    description: String,
    banner: String,
    videos: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      showBanner: true,
      showVideo: false,
      isVideoPlaying: false,
      currentBanner: null,
      currentVideos: []
    }
  },

  watch: {
    banner: {
      handler (banner) {
        this.currentBanner = banner
      },
      immediate: true
    },

    videos: {
      handler (videos) {
        const player_ = this.$refs.player

        if (player_) {
          player_.pause()
        }

        this.showVideo = false
        this.$nextTick(() => this.currentVideos = videos)
      },
      immediate: true
    }
  },

  components: {
    VImage,
    VPlayer,
    VFadeTransition
  },

  methods: {
    handleBannerToggle (show) {
      if (this.isVideoPlaying) this.showVideo = !show
    },

    handleVideoToggle (show) {
      const player_ = this.$refs.player

      player_.$player.unload(false)

      this.showBanner = !show
    },

    handleVideoPlaying () {
      this.isVideoPlaying = true
      this.showBanner = false
    },

    handleVideoEnded () {
      this.isVideoPlaying = true
      this.showVideo = false
    }
  }
}
</script>

<style lang="scss">
@import './VHero.scss';
</style>
