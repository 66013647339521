<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clip-path="url(#clip0_1090_2399)">
      <path d="M11.4622 0.837385C11.3334 0.727213 11.1695 0.666672 11 0.666672C10.8305 0.666672 10.6666 0.727213 10.5378 0.837385L0.333328 9.58405V19.8667C0.333328 20.4325 0.558089 20.9751 0.958167 21.3752C1.35824 21.7753 1.90087 22.0001 2.46666 22.0001H8.15555C8.34415 22.0001 8.52502 21.9251 8.65838 21.7918C8.79174 21.6584 8.86666 21.4775 8.86666 21.2889V17.0223C8.86666 16.4565 9.09142 15.9139 9.4915 15.5138C9.89158 15.1137 10.4342 14.8889 11 14.8889C11.5658 14.8889 12.1084 15.1137 12.5085 15.5138C12.9086 15.9139 13.1333 16.4565 13.1333 17.0223V21.2889C13.1333 21.4775 13.2082 21.6584 13.3416 21.7918C13.475 21.9251 13.6558 22.0001 13.8444 22.0001H19.5333C20.0991 22.0001 20.6417 21.7753 21.0418 21.3752C21.4419 20.9751 21.6667 20.4325 21.6667 19.8667V9.58405L11.4622 0.837385Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_1090_2399">
        <rect width="21.3333" height="21.3333" fill="currentColor" transform="translate(0.333328 0.666672)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'VIconHome'
}
</script>
