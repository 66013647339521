<template>
  <v-fade-transition
    @fadein="$emit('dialog:toggle', true)"
    @fadeout="$emit('dialog:toggle', false)"
  >
    <div
      v-show="showDialog"
      class="v-dialog"
    >
      <div
        class="v-dialog--backdrop"
        @click="showDialog = false"
      >
      </div>

      <div class="v-dialog--content">
        <slot />
      </div>

      <slot name="close" />
    </div>
  </v-fade-transition>
</template>

<script>
import VFadeTransition from '@/transitions/VFadeTransition.vue'

export default {
  name: 'VDialog',

  props: {
    show: Boolean
  },

  model: {
    prop: 'show',
    event: 'dialog:toggle'
  },

  data () {
    return {
      showDialog: false
    }
  },

  watch: {
    show: {
      handler (show) {
        this.showDialog = show
      },
      immediate: true
    }
  },

  components: {
    VFadeTransition
  }
}
</script>

<style lang="scss">
@import './VDialog.scss';
</style>
