import {
  login,
  loginOtp,
  resendOtpLogin,
  verifyOtpLogin,
  chooseOtpLogin,
  logout,
  checkPassword,
  fetchDeviceLogin,
  fetchDeviceToken,
  fetchUser,
  setToken,
  refreshToken,
  removeToken,
  removeProfile,
  checkConsentUpdate,
  acceptConsent
} from '../api'

const namespaced = true

const state = {
  credential: {
    channel: null,
    ref: null,
    token: null,
    candidates: []
  },
  token: null,
  consent: {
    checked: false,
    detail: null
  }
}

const mutations = {
  SET_CREDENTIAL_CHANNEL (state, channel) {
    state.credential.channel = channel
  },

  SET_CREDENTIAL_REF (state, ref) {
    state.credential.ref = ref
  },

  SET_CREDENTIAL_TOKEN (state, token) {
    state.credential.token = token
  },

  SET_CREDENTIAL_CANDIDATES (state, candidates) {
    state.credential.candidates = candidates
  },

  SET_TOKEN (state, token) {
    state.token = token
  },

  SET_CONSENT_CHECKED (state, checked) {
    state.consent.checked = checked
  },

  SET_CONSENT_DETAIL (state, detail) {
    state.consent.detail = detail
  },

  RESET_STATE (state) {
    state.credential.channel = null
    state.credential.ref = null
    state.credential.token = null
    state.credential.candidates = []
    state.token = null
    state.consent.checked = false
    state.consent.detail = null
  }
}

const actions = {
  ENSURE_ACCESS_TOKEN ({ state, commit }) {
    const d = new Date()
    const now = Math.floor(d.getTime() / 1000)
    const expire = state.token ? state.token.expires_in - 86400 : null

    if (!expire || now < expire) {
      return Promise.resolve()
    }

    return refreshToken(state.token.refresh_token)
      .then(res => res.data)
      .then(token => {
        localStorage.setItem('mnmaxToken', JSON.stringify(token))
        setToken(token.access_token)
        commit('SET_TOKEN', token)
      })
  },

  SIGNIN ({ commit, dispatch }, { username, password }) {
    return login(username, password)
      .then(res => res.data)
      .then(token => {
        localStorage.setItem('mnmaxToken', JSON.stringify(token))
        setToken(token.access_token)
        commit('SET_TOKEN', token)
      })
      .then(() => dispatch('FETCH_USER'))
      .then(() => dispatch('CHECK_CONSENT_UPDATE'))
      .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
      .then(() => dispatch('user/CHOOSE_PROFILE', {}, { root: true }))
      .then(() => dispatch('FETCH_NAVIGATION_BAR', null, { root: true }))
  },

  SIGNIN_OTP ({ commit }, { username, channel }) {
    return loginOtp(username, channel)
      .then(credential => {
        commit('SET_CREDENTIAL_CHANNEL', channel)
        commit('SET_CREDENTIAL_REF', credential.ref)
      })
  },

  RESEND_OTP_SIGNIN ({ state, commit }) {
    const { ref } = state.credential

    if (!ref) return Promise.resolve()

    return resendOtpLogin(ref)
      .then((credential) => commit('SET_CREDENTIAL_REF', credential.ref))
  },

  VERIFY_OTP_SIGNIN ({ state, commit, dispatch }, { otp }) {
    if (!otp) return Promise.reject()

    return verifyOtpLogin(otp, state.credential.ref)
      .then((res) => res.data)
      .then((credential) => {
        const hasCandidates = credential.candidates && credential.candidates.length > 0

        if (hasCandidates) {
          commit('SET_CREDENTIAL_TOKEN', credential.token)
          commit('SET_CREDENTIAL_CANDIDATES', credential.candidates)
          return
        }

        localStorage.setItem('mnmaxToken', JSON.stringify(credential))
        setToken(credential.access_token)
        commit('SET_TOKEN', credential)

        return dispatch('FETCH_USER')
          .then(() => dispatch('CHECK_CONSENT_UPDATE'))
          .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
          .then(() => dispatch('user/CHOOSE_PROFILE', {}, { root: true }))
          .then(() => dispatch('FETCH_NAVIGATION_BAR', null, { root: true }))
      })
  },

  CHOOSE_ACCOUNT_SIGNIN ({ state, commit, dispatch }, { index }) {
    const { token, candidates } = state.credential

    if (candidates.length === 0) return Promise.reject()

    const candidate = candidates[index]

    return chooseOtpLogin(token, candidate.id)
      .then((res) => res.data)
      .then(token => {
        localStorage.setItem('mnmaxToken', JSON.stringify(token))
        setToken(token.access_token)
        commit('SET_TOKEN', token)
      })
      .then(() => dispatch('FETCH_USER'))
      .then(() => dispatch('CHECK_CONSENT_UPDATE'))
      .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
      .then(() => dispatch('user/CHOOSE_PROFILE', {}, { root: true }))
      .then(() => dispatch('FETCH_NAVIGATION_BAR', null, { root: true }))
  },

  CHECK_AUTH ({ commit, dispatch, getters }) {
    const token = JSON.parse(localStorage.getItem('mnmaxToken'))
    const index = Number(localStorage.getItem('mnmaxUser'))

    if (!token || getters.isAuthenticated) {
      return Promise.resolve()
    }

    setToken(token.access_token)

    commit('SET_TOKEN', token)

    return dispatch('FETCH_USER')
      .then(() => dispatch('CHECK_CONSENT_UPDATE'))
      .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
      .then(() => dispatch('user/CHOOSE_PROFILE', { index }, { root: true }))
      .then(() => dispatch('FETCH_NAVIGATION_BAR', null, { root: true }))
      .catch((e) => {
        const error = e.response || {}

        if (error.status === 400 || error.status === 401) {
          dispatch('LOGOUT')
        }
      })
  },

  CHECK_DEVICE_LOGIN ({ commit, dispatch }, { deviceToken }) {
    return fetchDeviceLogin(deviceToken)
      .then(res => res.data)
      .then(token => {
        localStorage.setItem('mnmaxToken', JSON.stringify(token))
        setToken(token.access_token)
        commit('SET_TOKEN', token)
      })
      .then(() => dispatch('FETCH_USER'))
      .then(() => dispatch('CHECK_CONSENT_UPDATE'))
      .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
      .then(() => dispatch('user/CHOOSE_PROFILE', {}, { root: true }))
      .then(() => dispatch('FETCH_NAVIGATION_BAR', null, { root: true }))
  },

  CHECK_PASSWORD ({ rootState }, { password }) {
    const user = rootState.user.current

    if (user === null) {
      return Promise.reject()
    } else {
      return checkPassword(user.username, password)
    }
  },

  FETCH_DEVICE_TOKEN () {
    return fetchDeviceToken()
      .then((res) => res.data)
  },

  FETCH_USER ({ commit, dispatch }) {
    return dispatch('ENSURE_ACCESS_TOKEN')
      .then(() => fetchUser())
      .then(res => res.data)
      .then(user => commit('user/SET_USER', user, { root: true }))
  },

  CHECK_CONSENT_UPDATE ({ state, commit }) {
    return checkConsentUpdate()
      .then((detail) => commit('SET_CONSENT_DETAIL', detail))
      .then(() => {
        const { detail } = state.consent
        const checked = !(detail.accepted === null || detail.accepted < detail.current)

        commit('SET_CONSENT_CHECKED', checked)
      })
  },

  ACCEPT_CONSENT ({ commit }, { version }) {
    return acceptConsent(version)
      .then(() => commit('SET_CONSENT_CHECKED', true))
  },

  async LOGOUT ({ commit, dispatch }) {
    logout()

    localStorage.removeItem('mnmaxToken')
    localStorage.removeItem('mnmaxUser')

    removeToken()
    removeProfile()

    commit('RESET_STATE')
    commit('user/CLEAR_USER_DATA', null, { root: true })

    await dispatch('FETCH_NAVIGATION_BAR', null, { root: true })
  }
}

const getters = {
  isAuthenticated (state, getters, rootState, rootGetters) {
    return !!state.token && !!rootGetters['user/activeUser']
  },

  isConsentChecked (state) {
    return state.consent.checked
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
