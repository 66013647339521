<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
  >
    <g clip-path="url(#clip0_1194_7102)">
      <path d="M8.61538 0.5C5.89538 0.5 3.54215 1.62862 2.07631 3.46123C0.612923 5.29508 0 7.67046 0 10.2305V14.0385H3.69231V10.2305C3.69231 8.28585 4.17108 6.75969 4.96123 5.76892C5.75262 4.77938 6.81969 4.19231 8.61538 4.19231C10.416 4.19231 11.4806 4.74615 12.2695 5.73077C13.0572 6.71538 13.5385 8.26615 13.5385 10.2305V11.5769H17.2308V10.2305C17.2308 7.65569 16.5822 5.25323 15.1151 3.42308C13.6492 1.59292 11.3305 0.5 8.61538 0.5ZM11.0769 12.8077C9.03877 12.8077 7.38462 14.4618 7.38462 16.5V28.8077C7.38462 30.8458 9.03877 32.5 11.0769 32.5H28.3077C30.3458 32.5 32 30.8458 32 28.8077V16.5C32 14.4618 30.3458 12.8077 28.3077 12.8077H11.0769ZM19.6923 18.9615C20.3451 18.9615 20.9713 19.2209 21.4329 19.6825C21.8945 20.1441 22.1538 20.7702 22.1538 21.4231C22.1538 22.3314 21.6566 23.1117 20.9231 23.5388V26.3462C20.9231 27.0243 20.3705 27.5769 19.6923 27.5769C19.0142 27.5769 18.4615 27.0243 18.4615 26.3462V23.5388C17.728 23.1117 17.2308 22.3326 17.2308 21.4231C17.2308 20.7702 17.4901 20.1441 17.9517 19.6825C18.4134 19.2209 19.0395 18.9615 19.6923 18.9615Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_1194_7102">
        <rect width="32" height="32" fill="currentColor" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'VIconUnlock'
}
</script>
