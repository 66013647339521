<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 30"
    fill="none"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7359 25.6091C16.5578 25.5267 17.1131 26.3991 16.7249 27.1053C16.4513 27.6029 16.0657 28.0373 15.6038 28.3931C15.1327 28.7559 14.5862 29.0343 13.9999 29.2212C13.4134 29.4084 12.7906 29.5031 12.1655 29.5031C11.5405 29.5031 10.9177 29.4084 10.3311 29.2212C9.74485 29.0343 9.19836 28.7559 8.72733 28.3931C8.26539 28.0373 7.87976 27.6029 7.60621 27.1053C7.21797 26.3991 7.77328 25.5267 8.59517 25.6091C8.85151 25.6348 10.8577 25.8309 12.1655 25.8309C13.4734 25.8309 15.4795 25.6348 15.7359 25.6091Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.37197 1.95015C10.3909 0.506014 13.9286 0.456883 16.9897 1.81657L17.2633 1.93809C20.4709 3.36284 22.5245 6.44968 22.5245 9.84621V11.5307C22.5245 12.8847 22.8314 14.2225 23.4241 15.4507L23.778 16.1843C25.4033 19.5534 23.2866 23.4914 19.4874 24.1665L19.2733 24.2045C14.5733 25.0395 9.75383 25.0395 5.05388 24.2045C1.20361 23.5205 -0.833346 19.4258 1.02981 16.1154L1.33253 15.5777C2.07979 14.2499 2.47109 12.7642 2.47109 11.2547V9.55652C2.47109 6.33261 4.36935 3.38645 7.37197 1.95015Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconNotification'
}
</script>
