<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <path d="M11.8889 16.3333V18.1111H15.4444V19.8889H6.55556V18.1111H10.1111V16.3333H2.99289C2.87609 16.3326 2.76058 16.3089 2.65298 16.2634C2.54539 16.218 2.44783 16.1517 2.3659 16.0685C2.28397 15.9852 2.21929 15.8866 2.17557 15.7783C2.13184 15.67 2.10994 15.5541 2.11111 15.4373V3.896C2.11111 3.40089 2.51556 3 2.99289 3H19.0071C19.4942 3 19.8889 3.39911 19.8889 3.89511V15.4373C19.8889 15.9324 19.4844 16.3333 19.0071 16.3333H11.8889Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconSeries'
}
</script>
