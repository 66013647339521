import createHomeView from '@/views/HomeList/createHomeView.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: createHomeView('home'),
    meta: { title: 'Monomax' }
  },
  {
    path: '/movie',
    name: 'movie',
    component: createHomeView('movie'),
    meta: { title: 'Movies - Monomax' }
  },
  {
    path: '/series',
    name: 'series',
    component: createHomeView('series'),
    meta: { title: 'Series - Monomax' }
  },
  {
    path: '/:type(movies|series)/genre/:genre',
    name: 'genre',
    component: () => import(/* webpackChunkName: "genre" */ '@/views/HomeList/HomeGenre.vue'),
    meta: { title: 'Genre - Monomax' }
  },
  {
    path: '/:type(movies|series)/origin/:origin',
    name: 'origin',
    component: () => import(/* webpackChunkName: "origin" */ '@/views/HomeList/HomeOrigin.vue'),
    meta: { title: 'Origin - Monomax' }
  },
  {
    path: '/:type(movies|series)/origin/:origin/genre/:genre',
    name: 'origin-genre',
    component: () => import(/* webpackChunkName: "origin-genre" */ '@/views/HomeGrid/Genre.vue'),
    meta: { title: 'Origin Genre - Monomax' }
  },
  {
    path: '/category/:slug',
    name: 'category',
    component: () => import(/* webpackChunkName: "category" */ '@/views/HomeGrid/Category.vue'),
    meta: { title: 'Category - Monomax' }
  },
  {
    path: '/tag/:slug',
    name: 'tag',
    component: () => import(/* webpackChunkName: "tag" */ '@/views/HomeGrid/Tag.vue'),
    meta: { title: 'Tag - Monomax' }
  },
  {
    path: '/people/:slug',
    name: 'people',
    component: () => import(/* webpackChunkName: "people" */ '@/views/HomeGrid/People.vue'),
    meta: { title: 'People - Monomax' }
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: () => import(/* webpackChunkName: "favorite" */ '@/views/HomeGrid/Favorite.vue'),
    meta: { title: 'Favorite - Monomax' }
  },
  {
    path: '/upcoming',
    name: 'upcoming',
    component: () => import(/* webpackChunkName: "upcoming" */ '@/views/Upcoming'),
    meta: { title: 'Upcoming - Monomax' }
  },
  {
    path: '/shortclips/:slug',
    name: 'shortclips',
    component: () => import(/* webpackChunkName: "shortclips" */ '@/views/HomeGrid/ShortClips.vue'),
    meta: { title: 'Short Clips - Monomax' },
    props: { kind: 'shortclips' }
  },
  {
    path: '/live/sport',
    name: 'livesport',
    component: () => import(/* webpackChunkName: "livesport" */ '@/views/LiveSport'),
    meta: { title: 'Live Sport - Monomax' }
  },
  {
    path: '/live/tv',
    name: 'livetv',
    component: () => import(/* webpackChunkName: "livetv" */ '@/views/LiveTV'),
    meta: { title: 'Live TV - Monomax' }
  },
  {
    path: '/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "history" */ '@/views/HomeGrid/History.vue'),
    meta: { title: 'History - Monomax' }
  },
  {
    path: '/search',
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search'),
    children: [
      {
        path: '',
        name: 'search',
        component: () => import(/* webpackChunkName: "search-query" */ '@/views/Search/SearchQuery.vue'),
        meta: { title: 'Search - Monomax' }
      },
      {
        path: 'filters',
        name: 'search-filters',
        component: () => import(/* webpackChunkName: "search-filters" */ '@/views/Search/SearchFilters.vue'),
        meta: { title: 'Search Filters - Monomax' }
      }
    ]
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '@/views/Product'),
    meta: { title: 'Product - Monomax' },
  },
  // {
  //   path: '/campaign',
  //   name: 'campaign',
  //   component: () => import(/* webpackChunkName: "campaign" */ '@/views/Campaign'),
  //   meta: { title: 'Campaign - Monomax' }
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
    meta: { title: 'Login - Monomax' }
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile'),
    children: [
      {
        path: '',
        name: 'profile-choose',
        component: () => import(/* webpackChunkName: "profile-choose" */ '@/views/Profile/views/ProfileChoose.vue'),
        meta: { title: 'Choose User Profile - Monomax' }
      },
      {
        path: 'create',
        name: 'profile-create',
        component: () => import(/* webpackChunkName: "profile-create" */ '@/views/Profile/views/ProfileCreate.vue'),
        meta: { title: 'Create User Profile - Monomax' }
      },
      {
        path: 'edit/:id',
        name: 'profile-edit',
        component: () => import(/* webpackChunkName: "profile-edit" */ '@/views/Profile/views/ProfileEdit.vue'),
        meta: { title: 'Edit User Profile - Monomax' }
      },
      {
        path: 'delete/:id',
        name: 'profile-delete',
        component: () => import(/* webpackChunkName: "profile-delete" */ '@/views/Profile/views/ProfileDelete.vue'),
        meta: { title: 'Delete User Profile - Monomax' }
      },
      {
        path: 'avatar',
        name: 'profile-avatar',
        component: () => import(/* webpackChunkName: "profile-avatar" */ '@/views/Profile/views/ProfileAvatar.vue'),
        meta: { title: 'Choose Avatar - Monomax' }
      }
    ]
  },
  {
    path: '/purchase',
    component: () => import(/* webpackChunkName: "purchase" */ '@/views/Purchase'),
    children: [
      {
        path: 'package',
        name: 'purchase-package',
        component: () => import(/* webpackChunkName: "purchase-package" */ '@/views/Purchase/views/PurchasePackage.vue'),
        meta: { title: 'Purchase Package - Monomax' }
      },
      {
        path: 'package/finish',
        name: 'purchase-package-finish',
        component: () => import(/* webpackChunkName: "purchase-package-finish" */ '@/views/Purchase/views/PurchasePackageFinish.vue'),
        meta: { title: 'Purchase Package Finish - Monomax' }
      },
      {
        path: 'fasttrack/:id(\\d+)',
        name: 'purchase-fasttrack',
        component: () => import(/* webpackChunkName: "purchase-fasttrack" */ '@/views/Purchase/views/PurchaseFastTrack.vue'),
        meta: { title: 'Purchase FastTrack - Monomax' }
      },
    ]
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings'),
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import(/* webpackChunkName: "setting-profile" */ '@/views/Settings/views/SettingProfile.vue'),
        meta: { title: 'Settings - Monomax' }
      },
      {
        path: 'language',
        name: 'setting-language',
        component: () => import(/* webpackChunkName: "setting-language" */ '@/views/Settings/views/SettingLanguage.vue'),
        meta: { title: 'Setting Language - Monomax' }
      },
      {
        path: 'subtitle',
        name: 'setting-subtitle',
        component: () => import(/* webpackChunkName: "setting-subtitle" */ '@/views/Settings/views/SettingSubtitle.vue'),
        meta: { title: 'Setting Subtitle - Monomax' }
      },
      {
        path: 'devices',
        name: 'setting-devices',
        component: () => import(/* webpackChunkName: "setting-devices" */ '@/views/Settings/views/SettingDevices.vue'),
        meta: { title: 'Setting Devices - Monomax' }
      },
      {
        path: 'network',
        name: 'setting-network',
        component: () => import(/* webpackChunkName: "setting-network" */ '@/views/Settings/views/SettingNetwork.vue'),
        meta: { title: 'Setting Network - Monomax' }
      },
      {
        path: 'help',
        name: 'setting-help',
        component: () => import(/* webpackChunkName: "setting-help" */ '@/views/Settings/views/SettingHelp.vue'),
        meta: { title: 'Settings Help - Monomax' }
      },
      {
        path: 'about',
        name: 'setting-about',
        component: () => import(/* webpackChunkName: "setting-about" */ '@/views/Settings/views/SettingAbout.vue'),
        meta: { title: 'Settings About - Monomax' }
      }
    ]
  },
  {
    path: '/watch/:kind(vod|livetv|livesport)/:id',
    name: 'watch',
    component: () => import(/* webpackChunkName: "watch" */ '@/views/Watch'),
    meta: { title: 'Watch - Monomax' }
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import(/* webpackChunkName: "inbox" */ '@/views/Inbox'),
    meta: { title: 'Inbox - Monomax' }
  },
  {
    path: '/consent',
    name: 'consent',
    component: () => import(/* webpackChunkName: "consent" */ '@/views/Consent'),
    meta: { title: 'Consent - Monomax' }
  },
  {
    path: '/help',
    component: () => import(/* webpackChunkName: "help" */ '@/views/Help'),
    children: [
      {
        path: 'center',
        name: 'help-center',
        component: () => import(/* webpackChunkName: "help-center" */ '@/views/Help/views/HelpCenter.vue'),
        meta: { title: 'Help Center - Monomax' }
      },
      {
        path: 'purchase',
        name: 'help-purchase',
        component: () => import(/* webpackChunkName: "help-purchase" */ '@/views/Help/views/HelpPurchase.vue'),
        meta: { title: 'Help Purchase - Monomax' }
      },
      {
        path: 'claim_privilege',
        name: 'help-claim-privilege',
        component: () => import(/* webpackChunkName: "help-claim-privilege" */ '@/views/Help/views/HelpClaimPrivilege.vue'),
        meta: { title: 'Help Claim Privilege - Monomax' }
      }
    ]
  },
  {
    path: '/exit',
    name: 'exit',
    component: () => import(/* webpackChunkName: "exit" */ '@/views/Exit'),
    meta: { title: 'Exit - Monomax' }
  },
  {
    path: '/unsupported',
    name: 'unsupported',
    component: () => import(/* webpackChunkName: "unsupported" */ '@/views/Unsupported'),
    meta: { title: 'Unsupported - Monomax' }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error'),
    meta: { title: 'Error - Monomax' }
  }
]

export default routes
