<template>
  <div
    v-sn-focusable
    class="v-carousel--slide--link"
    data-sn-autofocus="disable"
    @click="handleClick"
    @keypress.enter="handleClick"
    role="link"
  >
    <v-image :aspectRatio="computeAspectRatio">
      <img
        class="v-image--image img-lazy"
        :data-lazy="computeImage"
        :alt="product.title"
      >
    </v-image>

    <div
      v-if="display === 'rank' && index !== null"
      class="trending-number"
    >
      {{ index + 1 }}
    </div>

    <div
      v-if="display === 'tvshow' && product.kind === 'tvshow'"
      class="latest-episode"
    >
      <span class="label-new">
        {{ $t('labels.new') }}
      </span>
      {{ $t('product.labels.episode_number', { episode: product.latest_ep }) }}
    </div>

    <div
      v-if="isComingSoon"
      class="coming-soon"
    >
      <span class="label-kind">
        {{ kindToText }}
      </span>

      <template v-if="product.start_at">
        {{ product.start_at | localTime($t('product.labels.coming_on', { date: 'D MMMM' })) }}
      </template>

      <template v-else>
        {{ $t('product.labels.coming_soon') }}
      </template>
    </div>

    <div
      v-if="hasProgress"
      class="progress-bar"
    >
      <div
        class="progress-bar--current"
        :style="{ width: `${Math.ceil((product.progress.second / product.progress.duration) * 100)}%` }"
      >
      </div>
    </div>
  </div>
</template>

<script>
import VImage from '@/components/VImage'

import _find from 'lodash/find'

export default {
  name: 'ProductItem',

  props: {
    index: Number,
    product: Object,
    display: {
      type: String,
      default: 'default'
    },
    track: Array
  },

  computed: {
    isComingSoon () {
      return 'start_at' in this.product
    },

    hasProgress () {
      return !!this.product.progress
    },

    kindToText () {
      const kind = this.product ? this.product.kind : null
      const kinds = ['movie', 'series', 'tvshow', 'live']

      if (kinds.indexOf(kind) !== -1) {
        return this.$t(`product.kind.${kind}`)
      } else {
        return kind
      }
    },

    computeImage () {
      var image = this.product.poster

      if (['landscape', 'tvshow'].indexOf(this.display) !== -1) {
        image = this.product.promo
      }

      return image
    },

    computeAspectRatio () {
      var aspect = 1.5

      if (['landscape', 'tvshow'].indexOf(this.display) !== -1) {
        aspect = .5625
      }

      return aspect
    }
  },

  components: {
    VImage
  },

  methods: {
    handleClick () {
      const track = _find(this.track, { on: 'click' })

      if (track && track.params) {
        this.$tracking.push({
          event: track.event,
          ...track.params
        })
      }

      this.$router.push({
        name: 'product',
        params: { id: this.product.id }
      })
    }
  }
}
</script>
