<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.442 25.8075C48.758 26.1239 48.9355 26.5528 48.9355 27C48.9355 27.4472 48.758 27.8761 48.442 28.1925L32.7213 43.9425C32.5657 44.1037 32.3795 44.2324 32.1737 44.3209C31.9679 44.4095 31.7465 44.4561 31.5224 44.4582C31.2983 44.4602 31.0761 44.4176 30.8687 44.3329C30.6612 44.2481 30.4728 44.1229 30.3143 43.9645C30.1557 43.8062 30.0303 43.6178 29.9454 43.4105C29.8605 43.2031 29.8177 42.9809 29.8195 42.7569C29.8213 42.5328 29.8678 42.3113 29.9561 42.1054C30.0445 41.8995 30.1729 41.7132 30.334 41.5575L43.177 28.6897L6.74954 28.7145C6.30198 28.7151 5.87252 28.5379 5.55563 28.2218C5.23875 27.9058 5.06038 27.4768 5.05979 27.0292C5.05919 26.5817 5.23641 26.1522 5.55245 25.8353C5.8685 25.5184 6.29748 25.3401 6.74503 25.3395L43.1815 25.317L30.3318 12.4425C30.1707 12.2867 30.0422 12.1005 29.9539 11.8946C29.8655 11.6886 29.8191 11.4672 29.8172 11.2431C29.8154 11.019 29.8582 10.7969 29.9432 10.5895C30.0281 10.3822 30.1535 10.1938 30.312 10.0354C30.4705 9.87707 30.659 9.75186 30.8664 9.66711C31.0739 9.58236 31.2961 9.53977 31.5201 9.54182C31.7442 9.54387 31.9656 9.59053 32.1715 9.67906C32.3773 9.7676 32.5634 9.89624 32.719 10.0575L48.442 25.8075Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconNext'
}
</script>
