import {
  setProfile,
  createProfile,
  updateProfile,
  deleteProfile,
  fetchHistory,
  fetchFavorite,
  addFavorite,
  deleteFavorite,
  checkMessageUnread
} from '@/api'

import _find from 'lodash/find'
import _filter from 'lodash/filter'

import { PACKAGE_TIER_STARTER } from '@/utils/constants'

const state = {
  current: null,
  activeProfileId: null,
  activePlayAdultContent: false,
  activeMessageUnread: false
}

const mutations = {
  SET_ACTIVE_PROFILE (state, profile) {
    state.activeProfileId = profile
  },

  SET_ACTIVE_PLAY_ADULT_CONTENT (state, active) {
    state.activePlayAdultContent = active
  },

  SET_ACTIVE_MESSAGE_UNREAD (state, active) {
    state.activeMessageUnread = active
  },

  SET_USER (state, user) {
    state.current = user
  },

  CLEAR_USER_DATA (state) {
    state.current = null
    state.activeProfileId = null
  }
}

const actions = {
  CREATE_PROFILE ({ dispatch }, { avatarId, profileName }) {
    return dispatch('auth/ENSURE_ACCESS_TOKEN', null, { root: true })
      .then(() => createProfile(avatarId, profileName))
      .then(() => dispatch('auth/FETCH_USER', null, { root: true }))
  },

  UPDATE_PROFILE ({ dispatch }, { id, profileName, avatarId }) {
    return dispatch('auth/ENSURE_ACCESS_TOKEN', null, { root: true })
      .then(() => updateProfile(id, profileName, avatarId))
      .then(() => dispatch('auth/FETCH_USER', null, { root: true }))
  },

  DELETE_PROFILE ({ state, dispatch }, { id }) {
    return dispatch('auth/ENSURE_ACCESS_TOKEN', null, { root: true })
      .then(() => deleteProfile(id))
      .then(() => dispatch('auth/FETCH_USER', null, { root: true }))
      .then(() => {
        if (state.activeProfileId === id) {
          return dispatch('CHOOSE_PROFILE', {})
        }
      })
  },

  CHOOSE_PROFILE ({ state, commit, getters }, { index }) {
    const user = state.current

    if (!user) return

    var profile = typeof index !== 'undefined' ? user.subprofiles[index] : null

    if (!profile) {
      profile = user.current_subprofile
      index = 0
    }

    localStorage.setItem('mnmaxUser', index)

    setProfile(profile.id)

    commit('SET_ACTIVE_PROFILE', profile.id)
    commit('SET_ACTIVE_PLAY_ADULT_CONTENT', false)

    return getters.activeUser
  },

  FETCH_HISTORY_ITEMS ({ dispatch, rootGetters }, { page, limit }) {
    return dispatch('auth/ENSURE_ACCESS_TOKEN', null, { root: true })
      .then(() => fetchHistory(page, limit))
      .then((res) => res.data)
      .then((items) => {
        return !rootGetters.isAllowedAdultContents
          ? _filter(items || [], (i) => i.product.rate !== 'RATE_18' && i.product.rate !== 'RATE_20')
          : items || []
      })
  },

  FETCH_FAVORITE_ITEMS ({ dispatch, rootGetters }, { page, limit }) {
    return dispatch('auth/ENSURE_ACCESS_TOKEN', null, { root: true })
      .then(() => fetchFavorite(page, limit))
      .then((res) => res.data)
      .then((items) => {
        return !rootGetters.isAllowedRateContents
          ? _filter(items || [], (i) => i.product.rate !== 'RATE_18' && i.product.rate !== 'RATE_20')
          : items || []
      })
  },

  ADD_PRODUCT_FAVORTIE ({ dispatch, commit }, { id }) {
    return dispatch('auth/ENSURE_ACCESS_TOKEN', null, { root: true })
      .then(() => addFavorite(id))
      .then(() => commit('product/SET_PRODUCT_FAVORITE', true, { root: true }))
  },

  DELETE_PRODUCT_FAVORTIE ({ dispatch, commit }, { id }) {
    return dispatch('auth/ENSURE_ACCESS_TOKEN', null, { root: true })
      .then(() => deleteFavorite(id))
      .then(() => commit('product/SET_PRODUCT_FAVORITE', false, { root: true }))
  },

  CHECK_MESSAGE_UNREAD ({ commit }) {
    return checkMessageUnread()
      .then((res) => res.data)
      .then((data) => commit('SET_ACTIVE_MESSAGE_UNREAD', !!data.count))
  }
}

const getters = {
  isUserExisted (state) {
    return !!state.current
  },

  isPackageRegistered (state) {
    return state.current
      ? !!state.current.package_registered
      : false
  },

  isPackageExpired (state, getters) {
    return getters.isPackageRegistered ? state.current.package_registered.is_expired : true
  },

  isUpgradePackage (state, getters) {
    return getters.isPackageRegistered && !getters.isPackageExpired ? getters.activeUser.package.tier === PACKAGE_TIER_STARTER : false
  },

  activeUser (state, getters) {
    const user = state.current
    const profile = getters.activeUserProfile

    if (!user || !profile) {
      return null
    }

    const pkg = user.package_registered ? user.package_registered.package : null

    return {
      id: user.id,
      username: user.username,
      name: profile ? profile.name : user.username,
      avatarUrl: profile ? profile.avatar_url : null,
      registeredAt: user.registered_at,
      package: pkg
        ? {
          id: pkg.id,
          name: pkg.name,
          devices: pkg.devices,
          tier: user.package_registered.tier,
          is_expired: user.package_registered.is_expired,
          expiredAt: user.package_registered.expires_at
        }
        : null,
      extraPackages: user.extra_packages,
      subprofiles: user.subprofiles || [],
      current_subprofile: profile
    }
  },

  activeUserProfile (state) {
    const user = state.current
    return user ? _find(user.subprofiles, { id: state.activeProfileId }) : null
  },

  activeExtraPackages (state, { isUserExisted, activeUser }) {
    return _filter(isUserExisted ? activeUser.extraPackages : [], { is_expired: false })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
