import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import VueToastification from 'vue-toastification'
import VueTracking from './plugins/vue-tracking'
import VueSpatialNavigation from './plugins/vue-spatial-navigation'
import VueMoment from './plugins/vue-moment'
import VuePusher from './plugins/vue-pusher'
import VueUnleash from './plugins/vue-unleash'
import { sync } from 'vuex-router-sync'
import 'intersection-observer'
import 'abort-controller/polyfill'
import 'velocity-animate'
import './_webos'
import './api'
import './_fontawesome'
import './assets/scss/main.scss'
import 'vue-toastification/dist/index.css'
import router from './router'
import store from './store'
import i18n from './i18n'
import App from './App.vue'

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: process.env.VUE_APP_SENTRY_RELEASE || 'no-job-id',

  integrations: [
    Sentry.browserTracingIntegration({ router })
  ],

  // Browser Tracing
  tracesSampleRate: process.env.NODE_ENV === 'production' ? .02 : 1,
  tracePropagationTargets: ['localhost', 'ngrok-free.app', 'spa.monomax.me'],

  allowUrls: process.env.NODE_ENV === 'production' ? ['ngrok-free.app', 'spa.monomax.me'] : [],

  denyUrls: [
    /chunk-vendors/,
    /node_modules/
  ],

  ignoreErrors: [
    /ChunkLoadError/i,
    /Loading(.+)chunk/i,
    /Unexpected token '?</,
    /The operation was aborted/i,
    /play\(\)/i,
  ]
})

Vue.config.productionTip = false

Vue.use(VueToastification, {
  closeButton: false,
  closeOnClick: true,
  transition: 'Vue-Toastification__slide',
  timeout: 7000
})

Vue.use(VueSpatialNavigation)
Vue.use(VueMoment)

Vue.use(VuePusher, {
  apiKey: process.env.VUE_APP_PUSHER_API_KEY,
  options: {
    wsHost: 'ws.monomax.me',
    wsPort: 443,
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    cluster: 'ap1',
    authEndpoint: process.env.VUE_APP_PUSHER_CHANNEL_AUTH
  },
  autoConnect: false
})

Vue.use(VueTracking, {
  trackerURL: process.env.VUE_APP_MONOMAX_TRACKER_URL,
  timeout: 10000, // 10 seconds
})

Vue.use(VueUnleash, {
  configs: {
    url: process.env.VUE_APP_UNLEASH_URL,
    clientKey: process.env.VUE_APP_UNLEASH_CLIENT_KEY,
    appName: process.env.VUE_APP_UNLEASH_APP_NAME,
    refreshInterval: 3600 // 60 minutes
  },
  autoStart: false
})

sync(store, router)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
