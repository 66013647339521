<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path d="M29.2863 14.5229L23.6613 19.3767L25.375 26.6354C25.4696 27.0295 25.4453 27.4427 25.3051 27.8229C25.1649 28.2031 24.9152 28.5332 24.5875 28.7716C24.2598 29.01 23.8687 29.1459 23.4638 29.1621C23.0589 29.1784 22.6583 29.0742 22.3125 28.8629L16 24.9779L9.68379 28.8629C9.33808 29.073 8.93792 29.1762 8.53372 29.1593C8.12951 29.1425 7.73933 29.0064 7.4123 28.7682C7.08527 28.5301 6.83601 28.2005 6.69593 27.821C6.55584 27.4414 6.53117 27.0289 6.62504 26.6354L8.34504 19.3767L2.72004 14.5229C2.41417 14.2586 2.19295 13.9099 2.08402 13.5206C1.97509 13.1313 1.98328 12.7185 2.10756 12.3338C2.23184 11.949 2.46671 11.6095 2.78283 11.3574C3.09895 11.1054 3.48231 10.9521 3.88504 10.9167L11.26 10.3217L14.105 3.43667C14.259 3.06144 14.5211 2.74048 14.858 2.51459C15.1949 2.2887 15.5913 2.16809 15.9969 2.16809C16.4025 2.16809 16.799 2.2887 17.1358 2.51459C17.4727 2.74048 17.7348 3.06144 17.8888 3.43667L20.7325 10.3217L28.1075 10.9167C28.5111 10.9508 28.8956 11.1032 29.2128 11.3549C29.5301 11.6066 29.766 11.9463 29.8911 12.3315C30.0161 12.7167 30.0248 13.1302 29.9158 13.5202C29.8069 13.9103 29.5853 14.2595 29.2788 14.5242L29.2863 14.5229Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconFavorite'
}
</script>
