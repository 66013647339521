<template>
  <span class="v-chip">
    <slot name="icon">
      <span
        v-if="icon"
        class="v-chip--icon"
      >
          <v-icon :icon="icon" />
      </span>
    </slot>

    <slot name="text">
      <span class="v-chip--text">
        {{ text }}
      </span>
    </slot>

    <slot />
  </span>
</template>

<script>
import VIcon from '@/components/VIcon'

export default {
  name: 'VChip',

  props: {
    icon: String,
    text: String
  },

  components: {
    VIcon
  }
}
</script>

<style lang="scss">
@import './VChip.scss';
</style>
