<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M13.0001 18V20H17.0001V22H7.00005V20H11.0001V18H2.99205C2.86065 17.9992 2.7307 17.9725 2.60965 17.9214C2.48861 17.8702 2.37885 17.7957 2.28668 17.702C2.19452 17.6084 2.12175 17.4975 2.07256 17.3756C2.02337 17.2538 1.99873 17.1234 2.00005 16.992V4.008C2.00005 3.451 2.45505 3 2.99205 3H21.0081C21.5561 3 22.0001 3.449 22.0001 4.007V16.992C22.0001 17.549 21.5451 18 21.0081 18H13.0001Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VIconTv'
}
</script>
