<template>
  <v-dialog
    :show="show"
    class="v-dialog--genre-list root-new"
    @dialog:toggle="handleToggle"
    @keydown.native="handleKeydown"
  >
    <v-carousel
      v-if="items.length"
      v-sn="snOptions"
      :perPage="perPage"
      gutter="12"
      :breakpoints="{
        1920: { gutter: 24 }
      }"
      centered
      @carousel:moved="handleListMoved"
      @carousel:ready="handleListReady"
      @mouseenter.native="handleListArrowToggle(true)"
      @mouseleave.native="handleListArrowToggle(false)"
      ref="genreList"
    >
      <v-carousel-slide
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
      >
        <div
          v-sn-focusable
          class="v-carousel--slide--link"
          @sn:willmove="handleListItemBeforeDeactive"
          @sn:active="handleListItemActive($event, itemIndex)"
          @mouseover="handleListItemAutoFocus"
          @keydown.enter="handleListItemClick(item)"
          @click.once="handleListItemClick(item)"
        >
          {{ item.name }}
        </div>
      </v-carousel-slide>

      <v-navigation
        v-model="showNavigation"
        slot="arrow"
        direction="horizontal"
        ref="navigation"
      />
    </v-carousel>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/VDialog'

import {
  VCarousel,
  VCarouselSlide
} from '@/components/VCarousel'

import VNavigation from '@/components/VNavigation'

import { keyCodes } from '@/utils/helpers'

export default {
  name: 'GenreListDialog',

  props: {
    show: Boolean,
    items: {
      type: Array,
      default: () => []
    }
  },

  model: {
    prop: 'show',
    event: 'dialog:toggle'
  },

  data () {
    return {
      snOptions: {
        section: 'genre-list',
        options: {
          straightOnly: true,
          leaveFor: {
            up: '',
            down: ''
          },
          restrict: 'self-only',
          disabled: true
        }
      },
      showNavigation: false,
      isListReady: false,
      isListMove: false,
      previousFocusedEl: null,
      perPage: 5,
      currentListItem: 0,
      activeItem: null
    }
  },

  computed: {
    activeRoute () {
      const item = this.activeItem || {}
      const { page, value, params } = item.more

      if (page === 'tag') {
        return { name: 'tag', params: { slug: value } }
      } else if (page === 'category') {
        return { name: 'category', params: { slug: value } }
      } else if (page === 'origin-home') {
        return { name: 'origin', params: { origin: params.origin, type: params.type } }
      } else if (page === 'genre-home') {
        return { name: 'genre',  params: { genre: params.genre, type: params.type } }
      } else if (page === 'origin-genre') {
        return { name: 'origin-genre', params: { origin: params.origin, genre: params.genre, type: params.type } }
      } else {
        return { name: 'home' }
      }
    }
  },

  watch: {
    show (show) {
      if (show) this.previousFocusedEl = document.activeElement
    },

    activePlatformCursor (active) {
      if (!active) this.showNavigation = false
    }
  },

  components: {
    VDialog,
    VCarousel,
    VCarouselSlide,
    VNavigation
  },

  mounted () {
    this.$el.style.display = 'block'
    this.$el.style.visibility = 'hidden'
  },

  methods: {
    _canListItemMove (direction) {
      if (['left', 'right'].indexOf(direction) === -1) {
        return false
      }

      const { slides, range, index, perPage, offset } = this.$refs.genreList
      const lastIndex = slides.length - 1
      const nextIndex = direction === 'left' ? Math.max(this.currentListItem - 1, 0) : Math.min(this.currentListItem + 1, lastIndex)
      const edgeStart = offset - 1
      const edgeEnd = Math.min(lastIndex - edgeStart, lastIndex)

      if (
        (slides.length <= perPage) ||
        (index === nextIndex) ||
        (range.start === 0 && ((direction === 'left' && nextIndex <= edgeStart) || (direction === 'right' && nextIndex <= edgeStart))) ||
        (range.end === lastIndex && ((direction === 'left' && nextIndex >= edgeEnd) || (direction === 'right' && nextIndex >= edgeEnd)))
      ) {
        return false
      } else {
        return true
      }
    },

    handleToggle (show) {
      this.$store.commit('SET_ACTIVE_PLATFORM_BACK', !show)
      this.$emit('dialog:toggle', show)

      this.$nextTick(() => {
        const section = this.snOptions.section

        if (show) {
          this.$sn.enable(section)
        } else {
          this.$sn.disable(section)
        }

        this.isSnActive = show

        this.$sn.resume()
        this.$sn.focus(show ? section : this.previousFocusedEl)
      })
    },

    handleKeydown (e) {
      const keycode = e.keyCode || e.which

      if (keyCodes.back.indexOf(keycode) !== -1) {
        this.$emit('dialog:toggle', false)
      }
    },

    handleListMoved () {
      this.updateListArrowDisplay()
      this.isListMove = false
    },

    handleListReady () {
      this.$refs.genreList.reload()

      this.updateListArrowDisplay()
      this.isListReady = true

      this.$el.style.display = 'none'
      this.$el.style.visibility = null
    },

    handleListArrowToggle (show) {
      if (this.items.length <= this.perPage) {
        return
      }

      this.$sn.set(this.snOptions.section, {
        restrict: show ? 'self-only' : 'none'
      })

      this.showNavigation = show
    },

    handleListItemBeforeDeactive (e) {
      if (this.isListMove) {
        e.preventDefault()
        return
      }

      const { direction } = e.detail

      if (this._canListItemMove(direction)) {
        const genreList = this.$refs.genreList

        this.isListMove = true

        if (direction === 'left') {
          genreList.go(this.currentListItem - 1)
        } else if (direction === 'right') {
          genreList.go(this.currentListItem + 1)
        }
      }
    },

    handleListItemActive (e, itemIndex) {
      this.currentListItem = itemIndex
    },

    handleListItemAutoFocus (e) {
      if (!this.isListMove) this.handleSnAutoFocus(e)
    },

    handleListItemClick (item) {
      this.activeItem = item

      this.$once('dialog:toggle', () => this.$router.push(this.activeRoute))
      this.$parent.showGenreList = false
    },

    updateListArrowDisplay () {
      const navigation = this.$refs.navigation
      const { index, finalIndex } = this.$refs.genreList

      navigation.$refs.left[0].$el.disabled = index === 0
      navigation.$refs.right[0].$el.disabled = index === finalIndex
    }
  }
}
</script>
