<template>
  <svg
    class="svg-inline--fa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g id="icon-movie-32">
      <path id="Vector" d="M3.88889 3.55557L5.66667 7.11113H8.33334L6.55556 3.55557H8.33334L10.1111 7.11113H12.7778L11 3.55557H12.7778L14.5556 7.11113H17.2222L15.4444 3.55557H18.1111C18.6 3.55557 19.0187 3.72979 19.3671 4.07824C19.7156 4.42668 19.8895 4.84505 19.8889 5.33335V16C19.8889 16.4889 19.715 16.9076 19.3671 17.256C19.0193 17.6045 18.6006 17.7784 18.1111 17.7778H3.88889C3.4 17.7778 2.98163 17.6039 2.63378 17.256C2.28593 16.9082 2.11171 16.4895 2.11111 16V5.33335C2.11111 4.84446 2.28534 4.42609 2.63378 4.07824C2.98223 3.73039 3.4006 3.55617 3.88889 3.55557Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VIconMovie'
}
</script>
